/*-----------------------------------------------------------------------------------

    Template Name: techwix - Technology IT Solutions Consultancy HTML5 Template
    Template URI: https://thepixelcurve.com/
    Description: A Library of Responsive Bootstrap5, HTML5, and CSS3 Website Templates
    Author: Pixelcurve
    Author URI: https://thepixelcurve.com/
    Version: 1.0


-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
    02. Header CSS
    2.1. Header Top CSS
    2.2 Offcanvas Menu CSS
    03. Hero CSS
    04. Service CSS
    05. About CSS
    06. Skill CSS
    07. Counter CSS
    08. Case Study CSS
    09. Features CSS
    10. Team CSS
    11. Testimonial CSS
    12. Choose Us CSS
    13. Brand CSS
    14. CTA CSS
    15. Video CSS
    16. Blog CSS
    17. Pricing CSS
    18. Blog Standard CSS
    19. Blog Details CSS
    20. Contact CSS
    21. Page Banner CSS
    22. Subscribe CSS
    23. Login Register CSS
    24. Footer CSS
    24.1. Footer Widget CSS
	

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/* @import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Jost:wght@300;400;500&display=swap"); */
/*-- Common Style --*/
*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  line-height: 1.75;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  color: #4c4d56;
  outline: none;
  visibility: visible;
  overflow-X: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Barlow", sans-serif;
  color: #0e0e0e;
  font-weight: 700;
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
}

h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input {
  transition: all 0.3s linear;
  outline: 0;
}
img{
  max-width: 100%;
}
a:focus, button:focus, img:focus, input:focus {
  outline: 0;
}

a:focus {
  color: none;
  outline: none;
  text-decoration: none;
}


a:hover {
  text-decoration: none;
  color: #0e0e0e !important;
  box-shadow: #0e0e0e !important;
}

ul, ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

button, input[type="submit"] {
  cursor: pointer;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
}

/*-- Common Classes --*/
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

/*--Section Spacing--*/
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 1199px) {
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
  .section-padding-02 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

.section-padding-03 {
  padding-top: 140px;
}

@media only screen and (max-width: 1199px) {
  .section-padding-03 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding-03 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-03 {
    padding-top: 60px;
  }
}

/*--Section Title--*/
.section-title .title {
  font-size: 48px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
    line-height: 36px;
  }
}

.section-title .title.white {
  color: #ffffff;
}

.section-title .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.section-title .sub-title.color-2 {
  color: #ff5538;
}

.section-title .sub-title.color-3 {
  color: #11ddf5;
}

.section-title .sub-title.white {
  color: #ffffff;
}

/*--Section Title Two--*/
.section-title2 {
  max-width: 690px;
  margin: 0 auto;
}

.section-title2 .title {
  font-size: 48px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  color: #333333;
}

.section-title2 .title span {
  color: #086ad8;
}

@media only screen and (max-width: 1199px) {
  .section-title2 .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title2 .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.section-title2 .sub-title {
  font-size: 12px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  color: #a1a1a1;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-title2 p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #4c4d56;
  padding: 0 50px;
  margin-top: 20px;
}

/*--Swiper Pagination--*/
.swiper-pagination .swiper-pagination-bullet {
  border-radius: 50%;
  background-color: #e8e8e8;
  width: 7px;
  height: 7px;
  transition: all 0.3s linear;
  position: relative;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  border: 1px solid #57585a;
  transform: scale(0);
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border-radius: 50%;
  transition: all 0.3s linear;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #086ad8;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  transform: scale(1);
}

/*--  Preloader Styles  --*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 999999;
}

#preloader .preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
}

#preloader .preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #086ad8;
  -webkit-animation: preloader 1.3s linear infinite;
          animation: preloader 1.3s linear infinite;
}

#preloader .preloader span:last-child {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@-webkit-keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*----------------------------------------*/
/*  02. Elements CSS
/*----------------------------------------*/
/*--
/*  2.4 - Form CSS
/*----------------------------------------*/
label {
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
}

/*Input Field & Select*/
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]),
textarea {
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #415674;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 10px 25px;
  max-width: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.3s linear;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):focus,
textarea:focus {
  outline: none;
  border-color: #086ad8;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

/*--
/*  2.3 - Button CSS
/*----------------------------------------*/
.btn-margin {
  margin-top: 40px;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.btn-wrap .btn {
  margin: 5px;
}

.btn {
  display: inline-block;
  overflow: hidden;
  transform: perspective(0) translateZ(0);
  background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-color: transparent;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  height: 60px;
  line-height: 56px;
  padding: 0px 70px;
  border: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.btn::before {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn::after {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn:focus, .btn:focus:active {
  box-shadow: none;
}

.btn-class:hover {
  text-decoration: none;
  color: #fff !important;
  box-shadow: #fff !important;
}
.btn:hover {
  color: #ffffff;
}

.btn:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.btn:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-3 {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding: 0 40px;
  background: #ff5538;
  color: #ffffff;
}

.btn-4 {
  height: 50px;
  line-height: 48px;
  font-size: 18px;
  padding: 0 45px;
  background: #11ddf5;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  color: #ffffff;
}

.btn-4:hover {
  background: #ffffff;
  color: #11ddf5;
}

.btn-round {
  border-radius: 100px;
}

.btn-square {
  border-radius: 0;
}

[class*="btn-icon-"] i {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 28px;
  text-align: center;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-icon-left {
  padding-left: 69px;
}

.btn-icon-left i {
  left: 0;
  right: auto;
}

.btn-icon-right {
  padding-right: 69px;
}

.btn-icon-right i {
  left: auto;
  right: 0;
}

.btn-primary {
  background-color: #086ad8;
  border-color: #086ad8;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #086ad8;
  border-color: #086ad8;
}

.btn-secondary {
  background-color: #000c29;
  border-color: #000c29;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #000c29;
  border-color: #000c29;
}

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0e0e0e;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.btn-white::after {
  background: #086ad8;
}

.btn-white::before {
  background: #086ad8;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
/*--
/*  2.1 - Header Top CSS
/*----------------------------------------*/
.header-section {
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  width: 100%;
  z-index: 999;
}

@media only screen and (max-width: 991px) {
  .header-section {
    padding: 15px 0;
  }
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo a img {
  width: 150px;
}

@media only screen and (max-width: 1199px) {
  .header-logo a img {
    width: 130px;
  }
}

.header-menu .main-menu {
  display: flex;
}

.header-menu .main-menu li {
  position: relative;
  padding: 30px 25px;
}

@media only screen and (max-width: 1199px) {
  .header-menu .main-menu li {
    padding: 30px 20px;
  }
}

.header-menu .main-menu li a {
  font-size: 15px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  /* text-transform: capitalize; */
  color: #0e0e0e;
}

.header-menu .main-menu li.active-menu > a {
  color: #086ad8;
}

.header-menu .main-menu li.active-menu > .menu-icon::after {
  color: #086ad8;
}

.header-menu .main-menu li .menu-icon::after {
  content: '\f16c';
  font-family: "flaticon";
  font-size: 10px;
  color: #94a0b9;
  margin-left: 5px;
  transition: all 0.3s linear;
}

.header-menu .main-menu li:hover > .menu-icon::after {
  color: #086ad8;
}

.header-menu .main-menu li:hover > a {
  color: #086ad8;
}

.header-menu .main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

ul.sub-menu {
  width: 150px !important;
  height: 98px !important;
}

.footer-social .social {
  margin-top: 25px !important;
  padding: 0 10px 18px 0px;
}

a.facbook {
  background: blue !important;
  color: #fff !important;
}

a.instagram {
  background: #f09433 !important;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important;
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important;
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%) !important;
}

.hero-content {
  margin: -160px 0 0 0;
}

.header-menu .main-menu li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 270px;
  background: #ffffff;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  border-radius: 5px;
  border-top: 3px solid #000000;
  opacity: 0;
  z-index: 99;
  visibility: hidden;
  transition: all 0.3s linear;
}

.header-menu .main-menu li .sub-menu li {
  padding: 0;
}

.header-menu .main-menu li .sub-menu li a {
  padding: 5px 25px;
  font-size: 15px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  position: relative;
  z-index: 1;
}

.header-menu .main-menu li .sub-menu li.active > a {
  color: #086ad8;
}

.header-menu .main-menu li .sub-menu li:hover > a {
  color: #086ad8;
}

.header-meta {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.header-search {
  margin-left: 25px;
  padding-left: 15px;
  position: relative;
}

.header-search::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 30px;
}

.header-search .search-btn {
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  color: #333333;
  transition: all 0.3s linear;
}

.header-search .search-btn:hover {
  color: #086ad8;
}

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  background: rgba(0, 0, 0, 0.9);
}

.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.search-wrap .search-inner .search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s linear;
}

.search-wrap .search-inner .search-close:hover {
  transform: rotate(90deg);
  color: #086ad8;
}

.search-wrap .search-inner .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.search-wrap .search-inner .search-cell .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .search-wrap .search-inner .search-cell .search-field-holder {
    width: 60%;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder {
    width: 80%;
  }
}

.search-wrap .search-inner .search-cell .search-field-holder .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: uppercase;
  background: transparent;
  font-size: 25px;
  font-weight: 400;
  color: #ffffff;
  border-bottom: 2px solid #898989;
  text-align: center;
  letter-spacing: 2px;
}

@media only screen and (max-width: 1199px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input {
    padding: 0 35px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input {
    font-size: 16px;
    padding: 0 15px;
  }
}

.search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #ffffff;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-webkit-input-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-webkit-input-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-webkit-input-placeholder {
    font-size: 14px;
  }
}

.search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-moz-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #ffffff;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-moz-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-moz-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-moz-placeholder {
    font-size: 14px;
  }
}

.search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-moz-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #ffffff;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-moz-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-moz-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input::-moz-placeholder {
    font-size: 14px;
  }
}

.search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 25px;
  color: #ffffff;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-ms-input-placeholder {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-ms-input-placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .search-wrap .search-inner .search-cell .search-field-holder .main-search-input:-ms-input-placeholder {
    font-size: 14px;
  }
}

.header-cart .cart-btn {
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  background: none;
  color: #333333;
  border: 0;
  position: relative;
  transition: all 0.3s linear;
}

.header-cart .cart-btn:hover {
  color: #086ad8;
}

.header-cart .cart-btn .count {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: #086ad8;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  position: absolute;
  top: -4px;
  right: -12px;
}

.dropdown-cart {
  max-width: 370px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  border: 0;
  transform: translateX(-50%) !important;
  left: 50% !important;
  top: 57px !important;
}

@media only screen and (max-width: 1399px) {
  .dropdown-cart {
    left: 0 !important;
    transform: translateX(-85%) !important;
  }
}

@media only screen and (max-width: 991px) {
  .dropdown-cart {
    top: 56px !important;
  }
}

@media only screen and (max-width: 449px) {
  .dropdown-cart {
    left: 0 !important;
    transform: translateX(-72%) !important;
  }
}

.dropdown-cart .cart-items {
  height: 280px;
  overflow-y: auto;
}

.dropdown-cart .cart-items .single-cart-item {
  display: flex;
  align-items: center;
  position: relative;
}

.dropdown-cart .cart-items .single-cart-item + .single-cart-item {
  margin-top: 30px;
}

.dropdown-cart .cart-items .single-cart-item .item-image img {
  border-radius: 50%;
}

.dropdown-cart .cart-items .single-cart-item .item-content {
  flex: 1;
  padding-right: 25px;
  padding-left: 20px;
}

.dropdown-cart .cart-items .single-cart-item .item-content .title {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  line-height: 1.3;
}

.dropdown-cart .cart-items .single-cart-item .item-content .quantity {
  font-size: 14px;
  color: #086ad8;
  line-height: 1.75;
  display: block;
}

.dropdown-cart .cart-items .single-cart-item .btn-close {
  position: absolute;
  width: 5px;
  height: 5px;
  top: 0;
  right: 5px;
}

.dropdown-cart .cart-total {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.dropdown-cart .cart-total .label {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.75;
  display: block;
  color: #000000;
}

.dropdown-cart .cart-total .value {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.75;
  display: block;
  color: #086ad8;
}

.dropdown-cart .cart-btns {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.dropdown-cart .cart-btns .btn {
  height: 50px;
  line-height: 46px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  font-size: 16px;
  padding: 0 35px;
  width: 48%;
}

.dropdown-cart .cart-btns .btn.btn-2 {
  background: #000c29;
  transition: all 0.3s linear;
}

.dropdown-cart .cart-btns .btn.btn-2:hover {
  color: #0e0e0e;
}

.dropdown-cart .cart-btns .btn.btn-2:hover::before {
  background: #ffffff;
}

.dropdown-cart .cart-btns .btn.btn-2:hover::after {
  background: #ffffff;
}

.header-btn {
  margin-left: 25px;
}

.header-btn .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 45px;
}

.header-toggle {
  margin-left: 30px;
}

.header-toggle button {
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle button span {
  width: 25px;
  height: 2px;
  background: #086ad8;
  margin: 5px 0;
  display: block;
}

.header-toggle button span:last-child {
  margin-bottom: 0;
}

.header-section-02 {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-section-02.sticky {
  background: #ffffff;
  border-bottom: 0;
}

.header-section-02.sticky .header-wrap .header-logo .logo-black {
  display: block;
}

.header-section-02.sticky .header-wrap .header-logo .logo-white {
  display: none;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li a {
  color: #0e0e0e;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li.active-menu > a {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li.active-menu > .menu-icon::after {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li .menu-icon::after {
  color: #94a0b9;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li:hover > .menu-icon::after {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li:hover > a {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li .sub-menu {
  border-top: 3px solid #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li .sub-menu li.active > a {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li .sub-menu li:hover > a {
  color: #ff5538;
  padding-left: 35px;
}

.header-section-02.sticky .header-wrap .header-cart .cart-btn {
  color: #333333;
}

.header-section-02.sticky .header-wrap .header-cart .cart-btn:hover {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-search .search-btn {
  color: #333333;
}

.header-section-02.sticky .header-wrap .header-search .search-btn:hover {
  color: #ff5538;
}

.header-section-02 .header-logo .logo-black {
  display: none;
}

.header-section-02 .header-menu .main-menu > li > a {
  color: #ffffff;
}

.header-section-02 .header-menu .main-menu > li.active-menu > a {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li.active-menu > .menu-icon::after {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .menu-icon::after {
  color: #ffffff;
}

.header-section-02 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li:hover > a {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu {
  border-top: 3px solid #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu li.active > a {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu li:hover > a {
  color: #ff5538;
}

.header-section-02 .header-cart .cart-btn {
  color: #ffffff;
}

.header-section-02 .header-cart .cart-btn:hover {
  color: #ff5538;
}

.header-section-02 .header-cart .cart-btn .count {
  background: #ff5538;
  color: #ffffff;
}

.header-section-02 .header-search .search-btn {
  color: #ffffff;
}

.header-section-02 .header-search .search-btn:hover {
  color: #ff5538;
}

.header-section-02 .dropdown-cart .cart-items .single-cart-item .item-content .quantity {
  color: #ff5538;
}

.header-section-02 .dropdown-cart .cart-total .value {
  color: #ff5538;
}

.header-section-02 .dropdown-cart .cart-btns .btn.btn-3 {
  background: #ff5538;
  color: #ffffff;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.header-section-02 .dropdown-cart .cart-btns .btn.btn-3:hover {
  background: #ffffff;
  color: #ff5538;
}

.header-section-02 .header-btn .btn.btn-3:hover {
  background: #ffffff;
  color: #ff5538;
}

.header-section-04 {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-section-04.sticky {
  background: #ffffff;
  border-bottom: 0;
}

.header-section-04.sticky .header-wrap .header-logo .logo-black {
  display: block;
}

.header-section-04.sticky .header-wrap .header-logo .logo-white {
  display: none;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li a {
  color: #0e0e0e;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li.active-menu > a {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li.active-menu > .menu-icon::after {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li .menu-icon::after {
  color: #94a0b9;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li:hover > .menu-icon::after {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li:hover > a {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li .sub-menu {
  border-top: 3px solid #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li .sub-menu li.active > a {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li .sub-menu li:hover > a {
  color: #086ad8;
  padding-left: 35px;
}

.header-section-04.sticky .header-wrap .header-cart .cart-btn {
  color: #333333;
}

.header-section-04.sticky .header-wrap .header-cart .cart-btn:hover {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-search .search-btn {
  color: #333333;
}

.header-section-04.sticky .header-wrap .header-search .search-btn:hover {
  color: #086ad8;
}

.header-section-04 .header-logo .logo-black {
  display: none;
}

.header-section-04 .header-menu .main-menu > li > a {
  color: #ffffff;
}

.header-section-04 .header-menu .main-menu > li.active-menu > a {
  color: #086ad8;
}

.header-section-04 .header-menu .main-menu > li.active-menu > .menu-icon::after {
  color: #086ad8;
}

.header-section-04 .header-menu .main-menu > li .menu-icon::after {
  color: #ffffff;
}

.header-section-04 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #086ad8;
}

.header-section-04 .header-menu .main-menu > li:hover > a {
  color: #086ad8;
}

.header-section-04 .header-cart .cart-btn {
  color: #ffffff;
}

.header-section-04 .header-cart .cart-btn:hover {
  color: #086ad8;
}

.header-section-04 .header-cart .cart-btn .count {
  background: #086ad8;
  color: #ffffff;
}

.header-section-04 .header-search .search-btn {
  color: #ffffff;
}

.header-section-04 .header-search .search-btn:hover {
  color: #086ad8;
}

.header-section-04 .dropdown-cart .cart-items .single-cart-item .item-content .quantity {
  color: #086ad8;
}

.header-section-04 .dropdown-cart .cart-total .value {
  color: #086ad8;
}

.header-section-04 .dropdown-cart .cart-btns .btn.btn-3 {
  background: #086ad8;
  color: #ffffff;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.header-section-04 .dropdown-cart .cart-btns .btn.btn-3:hover {
  background: #ffffff;
  color: #086ad8;
}

.header-section-05 .header-social {
  position: relative;
  padding-right: 25px;
  margin-right: 20px;
}

.header-section-05 .header-social::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 30px;
}

.header-section-05 .header-social ul li {
  display: inline-block;
}

.header-section-05 .header-social ul li + li {
  margin-left: 10px;
}

.header-section-05 .header-social ul li a {
  font-size: 15px;
  display: block;
  color: #565656;
}

.header-section-05 .header-social ul li a:hover {
  color: #086ad8;
}

.header-section-05 .header-search {
  padding-left: 0;
}

.header-section-05 .header-search::before {
  display: none;
}

.header-top-section {
  background: #030213;
}

.header-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.header-top-wrapper .header-top-info ul li {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  position: relative;
}

.header-top-wrapper .header-top-info ul li i {
  font-size: 20px;
  color: #11ddf5;
  position: relative;
  top: 1px;
  margin-right: 7px;
}

.header-top-wrapper .header-top-info ul li + li {
  padding-left: 30px;
  margin-left: 25px;
}

.header-top-wrapper .header-top-info ul li + li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #353542;
  width: 1px;
  height: 45px;
  transform: translateY(-46%);
}

.header-top-wrapper .header-social {
  display: flex;
  align-items: center;
}

.header-top-wrapper .header-social span {
  font-size: 14px;
  color: #11ddf5;
  display: inline-block;
  margin-right: 20px;
}

.header-top-wrapper .header-social ul li {
  display: inline-block;
}

.header-top-wrapper .header-social ul li + li {
  margin-left: 10px;
}

.header-top-wrapper .header-social ul li a {
  display: inline-block;
  font-size: 14px;
  color: #8c8c8c;
}

.header-top-wrapper .header-social ul li a:hover {
  color: #11ddf5;
}

.header-section-06 .header-menu {
  margin-left: auto;
}

.header-section-06 .header-menu .main-menu > li.active-menu > a {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li.active-menu > .menu-icon::after {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .menu-icon::after {
  color: #94a0b9;
}

.header-section-06 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li:hover > a {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu {
  border-top: 3px solid #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu li.active > a {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu li:hover > a {
  color: #11ddf5;
}

.header-section-06 .header-cart .cart-btn:hover {
  color: #11ddf5;
}

.header-section-06 .header-cart .cart-btn .count {
  background: #11ddf5;
  color: #ffffff;
}

.header-section-06 .header-search {
  padding-left: 0;
}

.header-section-06 .header-search::before {
  display: none;
}

.header-section-06 .header-search .search-btn:hover {
  color: #11ddf5;
}

.header-section-06 .dropdown-cart .cart-items .single-cart-item .item-content .quantity {
  color: #ff5538;
}

.header-section-06 .dropdown-cart .cart-total .value {
  color: #ff5538;
}

.header-section-06 .dropdown-cart .cart-btns .btn.btn-3 {
  background: #ff5538;
  color: #ffffff;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.header-section-06 .dropdown-cart .cart-btns .btn.btn-3:hover {
  background: #ffffff;
  color: #ff5538;
}

.header-section-06 .header-meta {
  position: relative;
  padding-left: 15px;
}

.header-section-06 .header-meta::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #cfcfcf;
  width: 1px;
  height: 25px;
}

.header-section-06 .header-btn .btn {
  height: 50px;
  line-height: 48px;
}

.header-section-06 .header-btn .btn:hover {
  background: #0f0948;
}

.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  -webkit-animation: sticky 1s;
          animation: sticky 1s;
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/*--
/*  2.3 - Header Top CSS
/*----------------------------------------*/
.offcanvas {
  background: #000c29;
  width: 320px;
}

@media only screen and (max-width: 575px) {
  .offcanvas {
    width: 280px;
  }
}

.offcanvas-header .close-btn {
  background: none;
  border: 0;
  font-size: 18px;
  color: #ffffff;
  transition: all 0.3s linear;
}

.offcanvas-header .close-btn:hover {
  transform: rotate(90deg);
  color: #086ad8;
}

.offcanvas-logo a img {
  width: 130px;
}

.offcanvas-menu {
  padding: 30px 0;
}

.offcanvas-menu .main-menu li {
  position: relative;
}

.offcanvas-menu .main-menu li a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  color: #ffffff;
  padding: 5px 0px;
  position: relative;
}

.offcanvas-menu .main-menu li .menu-expand {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.offcanvas-menu .main-menu li .menu-expand::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 2px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li .menu-expand::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  bottom: 0;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li:hover > a {
  color: #086ad8;
}

.offcanvas-menu .main-menu li.active > .menu-expand::after {
  height: 0;
}

.offcanvas-menu .main-menu li ul {
  padding-left: 6%;
  display: none;
}

/*----------------------------------------*/
/*  4.1. Pages CSS (All Page Styles)
/*----------------------------------------*/
/*--
/*  03 - Hero CSS
/*----------------------------------------*/
.techwix-hero-section {
  background: #f0f0f0;
  overflow: hidden;
  position: relative;
  padding-top: 85px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 1;
}

.techwix-hero-section .shape-2 {
  position: absolute;
  width: 100%;
  height: 130px;
  right: 0;
  left: 0px;
  bottom: -130px;
  background: #ffffff;
  transform: skewY(-3deg);
  transform-origin: 0;
  z-index: 2;
}

.techwix-hero-section .hero-content .sub-title {
  font-size: 18px;
  line-height: 30px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  height: 45px;
  line-height: 43px;
  background: #ffffff;
  color: #484848;
  padding-left: 10px;
  padding-right: 25px;
  display: inline-block;
  border-radius: 35px;
  margin-bottom: 10px;
}

.techwix-hero-section .hero-content .sub-title span {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  background: #086ad8;
  color: #ffffff;
  border-radius: 35px;
  padding: 0 15px;
  margin-right: 10px;
}

.techwix-hero-section .hero-content .title {
  font-size: 72px;
  line-height: 84px;
  color: #0e0e0e;
  margin-top: 15px;
}

.techwix-hero-section .hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #4c4d56;
  padding-right: 45px;
  margin-top: 30px;
}

.techwix-hero-section .hero-content .hero-btn .btn {
  margin-top: 45px;
}

.techwix-hero-section .hero-images .images {
  text-align: center;
  position: relative;
  bottom: -50px;
}

@media only screen and (max-width: 1399px) {
  .techwix-hero-section .shape-2 {
    bottom: -110px;
  }
  .techwix-hero-section .hero-content .sub-title {
    padding-right: 20px;
  }
  .techwix-hero-section .hero-content .sub-title span {
    font-size: 14px;
    padding: 0 14px;
    margin-right: 5px;
  }
  .techwix-hero-section .hero-content .title {
    font-size: 60px;
    line-height: 72px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section .hero-content .sub-title {
    font-size: 14px;
    padding-right: 15px;
  }
  .techwix-hero-section .hero-content .title {
    font-size: 50px;
    line-height: 62px;
  }
  .techwix-hero-section .hero-content p {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section .shape-2 {
    transform: skewY(-4deg);
    bottom: -75px;
  }
  .techwix-hero-section .hero-content {
    padding-top: 70px;
  }
  .techwix-hero-section .hero-images {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section .hero-content .sub-title {
    font-size: 14px;
    padding-right: 15px;
  }
  .techwix-hero-section .hero-content .sub-title span {
    font-size: 14px;
    padding: 0 14px;
    margin-right: 5px;
  }
  .techwix-hero-section .hero-content .title {
    font-size: 40px;
    line-height: 52px;
  }
  .techwix-hero-section .hero-content p {
    font-size: 18px;
  }
  .techwix-hero-section .hero-images {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-hero-section .hero-images .images {
    bottom: -15px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-hero-section .hero-content .sub-title {
    font-size: 11px;
  }
  .techwix-hero-section .hero-content .sub-title span {
    font-size: 12px;
    padding: 0 12px;
  }
}

.techwix-hero-section-02 {
  padding-top: 185px;
  padding-bottom: 310px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.techwix-hero-section-02::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(23, 26, 41, 0.3);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.techwix-hero-section-02 .svg-shape {
  position: absolute;
  bottom: -17%;
  left: -26%;
  width: 100%;
}

.techwix-hero-section-02 .hero-content .sub-title {
  font-size: 24px;
  line-height: 36px;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  color: #ffffff;
}

.techwix-hero-section-02 .hero-content .title {
  font-size: 80px;
  line-height: 90px;
  padding-left: 45px;
  margin-top: 30px;
  color: #ffffff;
  position: relative;
}

.techwix-hero-section-02 .hero-content .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff5538;
  height: 255px;
  width: 10px;
  transform: translateY(-50%);
}

.techwix-hero-section-02 .hero-content .hero-play-btn {
  margin-top: 55px;
}

.techwix-hero-section-02 .hero-content .hero-play-btn a {
  position: relative;
}

.techwix-hero-section-02 .hero-content .hero-play-btn a::before {
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 0;
  left: 14%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.techwix-hero-section-02 .hero-content .hero-play-btn .play-btn i {
  width: 80px;
  height: 80px;
  background: transparent;
  display: inline-block;
  line-height: 80px;
  text-align: center;
  font-size: 14px;
  color: #ff5538;
  background: #ffffff;
  border-radius: 50%;
}

.techwix-hero-section-02 .hero-content .hero-play-btn span {
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
  margin-left: 30px;
}

@media only screen and (max-width: 1399px) {
  .techwix-hero-section-02 .hero-content .title {
    font-size: 70px;
    line-height: 80px;
  }
  .techwix-hero-section-02 .hero-content .title::before {
    height: 220px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section-02 {
    padding-bottom: 260px;
  }
  .techwix-hero-section-02 .svg-shape {
    bottom: -16%;
  }
  .techwix-hero-section-02 .hero-content .title {
    font-size: 58px;
    line-height: 70px;
  }
  .techwix-hero-section-02 .hero-content .title::before {
    height: 195px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section-02 .svg-shape {
    left: -35%;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section-02 {
    padding-top: 150px;
  }
  .techwix-hero-section-02 .svg-shape {
    bottom: -18%;
    left: -55%;
  }
  .techwix-hero-section-02 .hero-content .sub-title {
    font-size: 20px;
  }
  .techwix-hero-section-02 .hero-content .title {
    font-size: 48px;
    line-height: 60px;
  }
  .techwix-hero-section-02 .hero-content .title::before {
    height: 180px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-hero-section-02 .svg-shape {
    bottom: -18%;
    left: -55%;
  }
  .techwix-hero-section-02 .hero-content .title {
    font-size: 35px;
    line-height: 45px;
    padding-left: 25px;
  }
  .techwix-hero-section-02 .hero-content .title::before {
    height: 140px;
    width: 8px;
  }
  .techwix-hero-section-02 .hero-content .hero-play-btn a::before {
    left: 13%;
    width: 90px;
    height: 90px;
  }
  .techwix-hero-section-02 .hero-content .hero-play-btn .play-btn i {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }
  .techwix-hero-section-02 .hero-content .hero-play-btn span {
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
    margin-left: 30px;
  }
}

.techwix-hero-section-03 {
  height: 1020px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.techwix-hero-section-03 .shape-1 {
  position: absolute;
  top: -34%;
  left: -9%;
  z-index: -1;
}

.techwix-hero-section-03 .shape-2 {
  position: absolute;
  left: 10%;
  top: -27%;
  z-index: -1;
}

.techwix-hero-section-03 .shape-3 {
  position: absolute;
  right: -4%;
  bottom: -19%;
  z-index: -1;
}

.techwix-hero-section-03 .shape-4 {
  position: absolute;
  top: 20%;
  right: -29%;
  z-index: -1;
}

.techwix-hero-section-03 .hero-content .sub-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.techwix-hero-section-03 .hero-content .title {
  font-size: 70px;
  line-height: 78px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}

.techwix-hero-section-03 .hero-content p {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 30px;
}

.techwix-hero-section-03 .hero-content .hero-btn .btn {
  margin-top: 40px;
}

@media only screen and (max-width: 1399px) {
  .techwix-hero-section-03 .hero-content .title {
    font-size: 40px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section-03 {
    height: 850px;
  }
  .techwix-hero-section-03 .shape-1 {
    top: -65%;
    left: -20%;
  }
  .techwix-hero-section-03 .shape-2 {
    left: 4%;
    top: -48%;
  }
  .techwix-hero-section-03 .shape-3 {
    right: -16%;
    bottom: -26%;
  }
  .techwix-hero-section-03 .shape-4 {
    top: 25%;
    right: -61%;
  }
  .techwix-hero-section-03 .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
  .techwix-hero-section-03 .hero-content p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section-03 {
    height: 750px;
  }
  .techwix-hero-section-03 .shape-1 {
    top: -79%;
    left: -30%;
  }
  .techwix-hero-section-03 .shape-2 {
    left: -4%;
    top: -59%;
  }
  .techwix-hero-section-03 .shape-4 {
    top: 17%;
    right: -73%;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section-03 .shape-1 {
    top: -85%;
    left: -45%;
  }
  .techwix-hero-section-03 .shape-2 {
    left: -12%;
    top: -65%;
  }
  .techwix-hero-section-03 .shape-3 {
    right: -30%;
    bottom: -35%;
  }
  .techwix-hero-section-03 .shape-4 {
    display: none;
  }
  .techwix-hero-section-03 .hero-content .title {
    font-size: 40px;
    line-height: 50px;
  }
  .techwix-hero-section-03 .hero-content p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-hero-section-03 .shape-1 {
    display: none;
  }
  .techwix-hero-section-03 .shape-2 {
    display: none;
  }
  .techwix-hero-section-03 .shape-3 {
    display: none;
  }
  .techwix-hero-section-03 .hero-content .sub-title {
    font-size: 15px;
  }
  .techwix-hero-section-03 .hero-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}

.techwix-hero-section-04 {
  height: 1070px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.techwix-hero-section-04::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(45, 45, 78, 0.5);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.techwix-hero-section-04 .shape-1 {
  position: absolute;
  left: 0;
  top: 15%;
  z-index: -1;
}

.techwix-hero-section-04 .shape-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.techwix-hero-section-04 .shape-svg {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  z-index: 1;
}

.techwix-hero-section-04 .shape-svg svg {
  fill: #ffffff;
}

.techwix-hero-section-04 .hero-content {
  max-width: 710px;
  margin: 0 auto;
}

.techwix-hero-section-04 .hero-content .sub-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.techwix-hero-section-04 .hero-content .title {
  font-size: 150px;
  line-height: 78px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 45px;
}

.techwix-hero-section-04 .hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 70px;
}

.techwix-hero-section-04 .hero-content .hero-btn .btn {
  margin-top: 50px;
}

@media only screen and (max-width: 1399px) {
  .techwix-hero-section-04 {
    height: 960px;
  }
  .techwix-hero-section-04 .hero-content .title {
    font-size: 130px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section-04 {
    height: 920px;
  }
  .techwix-hero-section-04 .hero-content .title {
    font-size: 110px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section-04 {
    height: 820px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section-04 .shape-1 {
    left: -5%;
    top: 15%;
  }
  .techwix-hero-section-04 .shape-2 {
    right: -60px;
    bottom: -50px;
  }
  .techwix-hero-section-04 .hero-content .sub-title {
    font-size: 18px;
  }
  .techwix-hero-section-04 .hero-content .title {
    font-size: 80px;
    margin-top: 30px;
  }
  .techwix-hero-section-04 .hero-content p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-hero-section-04 {
    height: 760px;
  }
  .techwix-hero-section-04 .shape-1 {
    display: none;
  }
  .techwix-hero-section-04 .shape-2 {
    display: none;
  }
  .techwix-hero-section-04 .hero-content .sub-title {
    font-size: 16px;
  }
  .techwix-hero-section-04 .hero-content .title {
    font-size: 60px;
    line-height: 1;
  }
}

.techwix-hero-section-05 {
  padding: 0 60px;
  margin-top: 90px;
}

.techwix-hero-section-05 .techwix-hero-wrap {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 150px;
  border-radius: 35px;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content {
  max-width: 710px;
  margin: 0 auto;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content .title {
  font-size: 70px;
  line-height: 78px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content .title span {
  color: #086ad8;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 40px;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-content .hero-btn .btn {
  margin-top: 50px;
}

.techwix-hero-section-05 .techwix-hero-wrap .hero-images .images {
  -webkit-animation: up-down 5s infinite;
          animation: up-down 5s infinite;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section-05 .techwix-hero-wrap .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
  .techwix-hero-section-05 .techwix-hero-wrap .hero-content p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section-05 .techwix-hero-wrap {
    padding-top: 90px;
    padding-bottom: 120px;
  }
  .techwix-hero-section-05 .techwix-hero-wrap .hero-images .images {
    text-align: center;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section-05 .techwix-hero-wrap .hero-content .title {
    font-size: 40px;
    line-height: 50px;
  }
  .techwix-hero-section-05 .techwix-hero-wrap .hero-content p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-hero-section-05 {
    padding: 0 0;
  }
  .techwix-hero-section-05 .techwix-hero-wrap .hero-content .title {
    font-size: 36px;
    line-height: 45px;
  }
}

.techwix-hero-section-06 {
  background: #0f0948;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 130px;
}

.techwix-hero-section-06 .hero-images {
  padding-top: 105px;
  position: relative;
  z-index: 1;
}

.techwix-hero-section-06 .hero-images .shape-1 {
  position: absolute;
  left: 5%;
  top: 17%;
  -webkit-animation: round-01 7s linear infinite;
          animation: round-01 7s linear infinite;
  z-index: -1;
}

.techwix-hero-section-06 .hero-content {
  padding-top: 170px;
}

.techwix-hero-section-06 .hero-content .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.techwix-hero-section-06 .hero-content .title {
  font-size: 70px;
  line-height: 78px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}

.techwix-hero-section-06 .hero-content .title span {
  color: #11ddf5;
}

.techwix-hero-section-06 .hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 40px;
}

.techwix-hero-section-06 .hero-content .hero-btn .btn-4 {
  height: 60px;
  line-height: 58px;
  padding: 0 65px;
  margin-top: 60px;
}

@-webkit-keyframes round-01 {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@keyframes round-01 {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@media only screen and (max-width: 1399px) {
  .techwix-hero-section-06 .hero-images .shape-1 {
    left: 2%;
  }
  .techwix-hero-section-06 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-hero-section-06 .hero-images .shape-1 {
    left: 2%;
  }
  .techwix-hero-section-06 .hero-content {
    padding-top: 120px;
  }
  .techwix-hero-section-06 .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-hero-section-06 .hero-content {
    padding-top: 40px;
  }
  .techwix-hero-section-06 .hero-images .shape-1 {
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-hero-section-06 .hero-images {
    padding-top: 80px;
  }
  .techwix-hero-section-06 .hero-images .shape-1 {
    left: 0;
  }
  .techwix-hero-section-06 .hero-content .title {
    font-size: 40px;
    line-height: 50px;
  }
  .techwix-hero-section-06 .hero-content p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-hero-section-06 .hero-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}

/*--
/*  03 - Service CSS
/*----------------------------------------*/
.service-content-wrap {
  padding-top: 45px;
}

@media only screen and (max-width: 767px) {
  .service-content-wrap {
    padding-top: 20px;
  }
}

.service-item {
  background: #ffffff;
  box-shadow: 0px 43px 100px 0px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  border-top: 10px solid #086ad8;
  transform: translate3d(0, 0, 0);
  padding: 30px 40px 40px;
  margin-top: 30px;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1399px) {
  .service-item {
    padding: 30px 30px 40px;
  }
}

.service-item.service-01, .service-item.service-03 {
  margin-top: 55px;
}

@media only screen and (max-width: 1199px) {
  .service-item.service-01, .service-item.service-03 {
    margin-top: 30px;
  }
}

.service-item .service-img img {
  height: 80px;
}

.service-item .service-content .title {
  font-size: 24px;
  line-height: 30px;
  padding-right: 15px;
  margin-top: 20px;
}

@media only screen and (max-width: 1399px) {
  .service-item .service-content .title {
    font-size: 22px;
    padding-right: 0;
  }
}

.service-item .service-content p {
  margin-top: 15px;
}

.service-item:hover {
  transform: translateY(-5px) translate3d(0, -5px, 0);
}

.techwix-service-section-02 {
  background: #f8f8f8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-service-section-02 .service-item-02 {
  background: #ffffff;
  box-shadow: 0px 43px 100px 0px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 40px 45px 45px;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
}

.techwix-service-section-02 .service-item-02 .service-img {
  position: relative;
  z-index: 1;
}

.techwix-service-section-02 .service-item-02 .service-img img {
  height: 55px;
  position: relative;
  left: 15px;
}

.techwix-service-section-02 .service-item-02 .service-img::before {
  content: "";
  position: absolute;
  left: 0;
  top: -18%;
  background: #ff5538;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  transition: all 0.3s linear;
  z-index: -1;
}

.techwix-service-section-02 .service-item-02 .next-icon {
  position: absolute;
  right: 15%;
  top: 15%;
}

.techwix-service-section-02 .service-item-02.service-2, .techwix-service-section-02 .service-item-02.service-4 {
  margin-top: 55px;
}

.techwix-service-section-02 .service-item-02 .service-content {
  position: relative;
  z-index: 5;
}

.techwix-service-section-02 .service-item-02 .service-content .title {
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
}

.techwix-service-section-02 .service-item-02 .service-content p {
  margin-top: 15px;
  transition: all 0.3s linear;
}

.techwix-service-section-02 .service-item-02:hover .service-content .title a {
  color: #ff5538;
}

.techwix-service-section-02 .more-service-content {
  margin-top: 30px;
}

.techwix-service-section-02 .more-service-content p {
  font-size: 16px;
  line-height: 30px;
}

.techwix-service-section-02 .more-service-content p a {
  font-weight: 600;
  color: #ff5538;
}

.techwix-service-section-02 .more-service-content p a i {
  position: relative;
  top: 1px;
}

@media only screen and (max-width: 1199px) {
  .techwix-service-section-02 .service-item-02.service-2, .techwix-service-section-02 .service-item-02.service-4 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-service-section-02 .service-item-02 {
    padding: 40px 40px 45px;
  }
}

.techwix-service-section-03 {
  background: #010f31;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 110px;
}

.techwix-service-section-03 .service-wrap-03 {
  position: relative;
  z-index: 5;
  margin-top: -140px;
}

.techwix-service-section-03 .service-item-03 {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 45px 35px;
  margin-top: 30px;
  position: relative;
}

.techwix-service-section-03 .service-item-03 .service-content .title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 20px;
}

.techwix-service-section-03 .service-item-03 .service-content p {
  font-size: 14px;
  line-height: 30px;
  padding-top: 20px;
}

.techwix-service-section-03 .service-item-03 .service-content .read-more {
  margin-top: 20px;
}

.techwix-service-section-03 .service-item-03 .service-content .read-more a {
  font-size: 14px;
  color: #333333;
}

.techwix-service-section-03 .service-item-03 .service-content .read-more a i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #ebebeb;
  border-radius: 50%;
  color: #086ad8;
  margin-right: 5px;
  transition: all 0.3s linear;
}

.techwix-service-section-03 .service-item-03:hover .service-content .read-more a {
  color: #086ad8;
}

.techwix-service-section-03 .service-item-03:hover .service-content .read-more a i {
  transform: rotate(90deg);
}

.techwix-service-section-04 .service-top .experience-wrap {
  position: relative;
}

.techwix-service-section-04 .service-top .experience-wrap .shape-1 {
  position: absolute;
  left: 0;
  top: -35px;
  z-index: -1;
}

.techwix-service-section-04 .service-top .experience-wrap .experience {
  width: 370px;
  height: 370px;
  background: #ffffff;
  border-radius: 50%;
  padding: 140px 0;
  position: absolute;
  left: 20px;
  top: 20px;
}

.techwix-service-section-04 .service-top .experience-wrap .experience .number {
  font-size: 200px;
  line-height: 54px;
  font-weight: 600;
  color: #086ad8;
}

.techwix-service-section-04 .service-top .experience-wrap .experience span {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 80px;
}

.techwix-service-section-04 .service-top .service-content {
  padding-right: 205px;
  padding-top: 45px;
}

.techwix-service-section-04 .service-top .service-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
}

.techwix-service-section-04 .service-bottom {
  padding-top: 190px;
}

.techwix-service-section-04 .service-bottom .service-item-03 {
  margin-top: 30px;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-img {
  overflow: hidden;
  border-radius: 10px;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-img a {
  display: block;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-img a img {
  transition: all 0.3s linear;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-text {
  background: #ffffff;
  padding: 25px;
  padding-left: 20px;
  position: relative;
  margin-top: -85px;
  width: 300px;
  transition: all 0.3s linear;
  z-index: 1;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-text .title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 600;
  position: relative;
}

.techwix-service-section-04 .service-bottom .service-item-03 .service-text .title::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 3px;
  height: 50px;
  transform: translateY(-50%);
}

.techwix-service-section-04 .service-bottom .service-item-03:hover .service-img a img {
  transform: scale(1.1);
}

.techwix-service-section-04 .service-bottom .more-service-content {
  margin-top: 50px;
}

.techwix-service-section-04 .service-bottom .more-service-content p {
  font-size: 16px;
  line-height: 30px;
}

.techwix-service-section-04 .service-bottom .more-service-content p a {
  font-weight: 600;
  color: #086ad8;
}

.techwix-service-section-04 .service-bottom .more-service-content p a i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.techwix-service-section-04 .service-bottom .more-service-content p a:hover i {
  margin-left: 5px;
}

@media only screen and (max-width: 1199px) {
  .techwix-service-section-04 .service-top .service-content {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-service-section-04 {
    padding-top: 100px;
  }
  .techwix-service-section-04 .service-top .experience-wrap .shape-1 {
    left: 50%;
    top: -35px;
    transform: translateX(-45%);
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience {
    position: relative;
    margin: auto;
  }
  .techwix-service-section-04 .service-top .service-content {
    padding-top: 130px;
  }
  .techwix-service-section-04 .service-bottom {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-service-section-04 .service-top .experience-wrap .shape-1 {
    width: 370px;
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience {
    width: 325px;
    height: 325px;
    padding: 110px 0;
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience .number {
    font-size: 160px;
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience span {
    font-size: 22px;
    margin-top: 70px;
  }
  .techwix-service-section-04 .service-bottom {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-service-section-04 .service-top .experience-wrap .shape-1 {
    width: 320px;
    transform: translateX(-50%);
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience {
    width: 290px;
    height: 290px;
    padding: 90px 0;
    left: 0;
    top: 0;
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience .number {
    font-size: 140px;
  }
  .techwix-service-section-04 .service-top .experience-wrap .experience span {
    font-size: 20px;
    margin-top: 50px;
  }
  .techwix-service-section-04 .service-top .service-content {
    padding-top: 100px;
  }
}

.techwix-service-section-05 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 160px;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .techwix-service-section-05 {
    padding-bottom: 120px;
  }
}

.techwix-service-section-05::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  /* background-image: url(../images/bg/service-bg4.jpg); */
  width: 100%;
  height: 75%;
  z-index: -1;
}

.techwix-service-section-05 .service-wrap {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  padding-left: 105px;
  padding-right: 105px;
  padding-bottom: 90px;
}

.techwix-service-section-05 .section-title {
  max-width: 560px;
  margin: 0 auto;
}

.techwix-service-section-05 .service-content-wrap {
  padding-top: 60px;
}

.techwix-service-section-05 .service-item-04 {
  margin-top: 30px;
}

.techwix-service-section-05 .service-item-04 .service-img img {
  transition: all 0.3s linear;
}

.techwix-service-section-05 .service-item-04 .service-content .title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 600;
  margin-top: 30px;
  padding-right: 70px;
}

.techwix-service-section-05 .service-item-04 .service-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.techwix-service-section-05 .service-item-04:hover .service-img img {
  transform: scale(1.1);
}

@media only screen and (max-width: 1399px) {
  .techwix-service-section-05 .service-item-04 .service-content .title {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-service-section-05 {
    padding-bottom: 100px;
  }
  .techwix-service-section-05 .service-wrap {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-service-section-05 .service-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
  .techwix-service-section-05 .service-item-04 .service-content .title {
    padding-right: 0px;
  }
}

.techwix-service-section-06 {
  background: #eaf3f9;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-service-section-06 .section-title {
  max-width: 650px;
  margin: 0 auto;
}

.techwix-service-section-06 .service-content-wrap {
  padding-top: 30px;
}

.service-item-05 {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 51px 45px;
  margin-top: 30px;
}

@media only screen and (max-width: 1399px) {
  .service-item-05 {
    padding: 40px 35px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .service-item-05 {
    padding: 40px 30px 45px;
  }
}

.service-item-05 .service-img img {
  transition: all 0.3s linear;
}

.service-item-05 .service-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 25px;
}

.service-item-05 .service-content .title:hover a {
  color: #11ddf5;
}

.service-item-05 .service-content p {
  font-size: 14px;
  line-height: 30px;
  margin-top: 15px;
}

.service-item-05:hover .service-img img {
  transform: scale(1.1);
}

.techwix-service-section-07 {
  padding-bottom: 280px;
}

.techwix-service-section-07 .section-title {
  max-width: 810px;
  margin: 0 auto;
}

.techwix-service-section-07 .service-content-wrap {
  padding-top: 80px;
}

.techwix-service-section-07 .experience-wrap {
  position: relative;
}

.techwix-service-section-07 .experience-wrap .shape-1 {
  position: absolute;
  left: 102px;
  top: -28px;
  -webkit-animation: round-01 5s linear infinite;
          animation: round-01 5s linear infinite;
  z-index: -1;
}

.techwix-service-section-07 .experience-wrap .experience {
  width: 370px;
  height: 370px;
  background: linear-gradient(90deg, #693eaa 0%, #01ebf2 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  padding: 130px 90px;
  position: absolute;
  left: 150px;
  top: 20px;
}

.techwix-service-section-07 .experience-wrap .experience .number {
  font-size: 170px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.techwix-service-section-07 .experience-wrap .experience span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  color: #ffffff;
  margin-top: 60px;
  padding-left: 10px;
}

.techwix-service-section-07 .service-content {
  padding-right: 225px;
}

.techwix-service-section-07 .service-content .text {
  font-size: 18px;
  line-height: 30px;
}

.techwix-service-section-07 .service-content .service-list {
  padding-top: 15px;
}

.techwix-service-section-07 .service-content .service-list ul li {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top: 20px;
}

.techwix-service-section-07 .service-content .service-list ul li .service-icon {
  color: #11ddf5;
  margin-right: 8px;
}

@media only screen and (max-width: 1399px) {
  .techwix-service-section-07 .service-content {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-service-section-07 .experience-wrap .shape-1 {
    left: 0px;
    top: -28px;
  }
  .techwix-service-section-07 .experience-wrap .experience {
    padding: 130px 90px;
    left: 45px;
    top: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-service-section-07 {
    padding-bottom: 100px;
  }
  .techwix-service-section-07 .experience-wrap .shape-1 {
    left: 115px;
    transform: translateX(-50%);
  }
  .techwix-service-section-07 .experience-wrap .experience {
    left: 0;
    position: relative;
    margin: 0 auto;
  }
  .techwix-service-section-07 .service-content {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-service-section-07 {
    padding-bottom: 80px;
  }
  .techwix-service-section-07 .experience-wrap .shape-1 {
    left: 30px;
    top: -50px;
  }
  .techwix-service-section-07 .experience-wrap .experience {
    width: 325px;
    height: 325px;
    padding: 105px 70px;
  }
  .techwix-service-section-07 .experience-wrap .experience .number {
    font-size: 150px;
  }
  .techwix-service-section-07 .experience-wrap .experience span {
    font-size: 16px;
    margin-top: 50px;
  }
  .techwix-service-section-07 .service-content {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-service-section-07 .experience-wrap .shape-1 {
    width: 350px;
    left: 0;
    top: -25px;
  }
  .techwix-service-section-07 .experience-wrap .experience {
    width: 290px;
    height: 290px;
    padding: 80px 65px;
    left: 0;
    top: 0;
  }
  .techwix-service-section-07 .experience-wrap .experience .number {
    font-size: 140px;
  }
  .techwix-service-section-07 .experience-wrap .experience span {
    font-size: 16px;
  }
  .techwix-service-section-07 .service-content {
    margin-top: 85px;
  }
}

/*--
/*  03 - CTA CSS
/*----------------------------------------*/
.techwix-cta-section .cta-wrap {
  background: #e6f0fb;
  max-width: 845px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  padding: 40px 125px;
}

@media only screen and (max-width: 575px) {
  .techwix-cta-section .cta-wrap {
    padding: 40px 90px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-cta-section .cta-wrap {
    padding: 40px 70px;
  }
}

.techwix-cta-section .cta-wrap .cta-icon {
  position: absolute;
  left: 30px;
  top: -60px;
  height: 85px;
  width: 85px;
  line-height: 85px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 20px 43px 0px rgba(0, 0, 0, 0.09);
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section .cta-wrap .cta-icon {
    top: -40px;
  }
}

.techwix-cta-section .cta-wrap .cta-content p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #4c4d56;
}

.techwix-cta-section .cta-wrap .cta-content p a {
  font-weight: 600;
  color: #086ad8;
}

.techwix-cta-section .cta-wrap .cta-content p a:hover {
  color: #0e0e0e;
}

.techwix-cta-section-02 .cta-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 10px;
  padding: 55px 105px;
  margin-bottom: -90px;
}

.techwix-cta-section-02 .cta-wrap .cta-content p {
  font-size: 30px;
  line-height: 44px;
  font-weight: 600;
  padding-left: 90px;
  color: #ffffff;
}

.techwix-cta-section-02 .cta-wrap .cta-content .cta-icon {
  width: 115px;
  height: 115px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 35px;
  background: #010f31;
}

.techwix-cta-section-02 .cta-wrap .cta-btn {
  text-align: right;
}

.techwix-cta-section-02 .cta-wrap .cta-btn .btn {
  font-size: 22px;
  background: #1f9f29;
  color: #0e0e0e;
  padding: 0 35px;
}

.techwix-cta-section-02 .cta-wrap .cta-btn .btn:hover {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section-02 .cta-wrap .cta-content {
    text-align: center;
    margin-top: 40px;
  }
  .techwix-cta-section-02 .cta-wrap .cta-content .cta-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .techwix-cta-section-02 .cta-wrap .cta-content p {
    padding-left: 0;
  }
  .techwix-cta-section-02 .cta-wrap .cta-btn {
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-cta-section-02 .cta-wrap {
    padding: 55px 60px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-cta-section-02 .cta-wrap {
    padding: 55px 25px;
  }
}

.techwix-cta-section-03 .cta-wrap {
  padding: 90px 105px;
  border-radius: 10px;
  background: #0c155f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -85px;
  position: relative;
  z-index: 5;
}

.techwix-cta-section-03 .cta-wrap .cta-content {
  padding-right: 60px;
}

@media only screen and (max-width: 1399px) {
  .techwix-cta-section-03 .cta-wrap .cta-content {
    padding-right: 30px;
  }
}

.techwix-cta-section-03 .cta-wrap .cta-content .title {
  padding-left: 30px;
  position: relative;
}

.techwix-cta-section-03 .cta-wrap .cta-content .title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 5px;
  height: 100%;
  transform: translateY(-45%);
}

.techwix-cta-section-03 .cta-wrap .cta-info p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #ffffff;
  margin-top: 10px;
}

.techwix-cta-section-03 .cta-wrap .cta-info .number {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: #4bfff4;
}

@media only screen and (max-width: 1199px) {
  .techwix-cta-section-03 .cta-wrap .cta-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section-03 .cta-wrap {
    padding: 90px 75px;
  }
  .techwix-cta-section-03 .cta-wrap .cta-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-cta-section-03 .cta-wrap {
    padding: 70px 40px;
  }
  .techwix-cta-section-03 .cta-wrap .cta-info .number {
    font-size: 28px;
    line-height: 40px;
  }
}

.techwix-cta-section-04 {
  background: #0f0948;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-cta-section-04 .cta-left {
  padding-right: 115px;
}

.techwix-cta-section-04 .cta-left .section-title .title {
  position: relative;
  padding-left: 40px;
}

.techwix-cta-section-04 .cta-left .section-title .title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 5px;
  height: 100%;
  transform: translateY(-45%);
}

.techwix-cta-section-04 .cta-left .cta-info {
  display: flex;
  align-items: center;
  margin-top: 60px;
}

.techwix-cta-section-04 .cta-left .cta-info .cta-text {
  flex: 1;
  margin-left: 30px;
}

.techwix-cta-section-04 .cta-left .cta-info .cta-text p {
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.techwix-cta-section-04 .cta-left .cta-info .cta-text .number {
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.techwix-cta-section-04 .cta-right {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 360px;
}

.techwix-cta-section-04 .cta-right::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 55%;
  background: #27225b;
  width: 360px;
  height: 1px;
  transform: translateY(-50%);
}

.techwix-cta-section-04 .cta-right::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: #27225b;
  width: 1px;
  height: 280px;
  transform: translate(-50%, -50%);
}

.techwix-cta-section-04 .cta-right .counter-item-box {
  position: relative;
}

.techwix-cta-section-04 .cta-right .counter-item.counter-1, .techwix-cta-section-04 .cta-right .counter-item.counter-2 {
  padding-top: 45px;
  margin-top: 25px;
}

.techwix-cta-section-04 .cta-right .counter-item span {
  font-size: 48px;
  line-height: 26px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #11ddf5;
}

.techwix-cta-section-04 .cta-right .counter-item p {
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .techwix-cta-section-04 .cta-left {
    padding-right: 0px;
  }
  .techwix-cta-section-04 .cta-right {
    margin-top: 65px;
  }
  .techwix-cta-section-04 .cta-right::before {
    width: 320px;
  }
  .techwix-cta-section-04 .cta-right::after {
    height: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-cta-section-04 .cta-right .counter-item span {
    font-size: 42px;
  }
}

.techwix-cta-section-05 .cta-wrap {
  background: #0f0948;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: -85px;
  position: relative;
  z-index: 5;
  padding: 80px 110px 105px;
}

.techwix-cta-section-05 .cta-wrap .cta-content .title {
  font-size: 42px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.techwix-cta-section-05 .cta-wrap .cta-content .cta-btn .btn-4 {
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 35px;
}

@media only screen and (max-width: 1199px) {
  .techwix-cta-section-05 .cta-wrap {
    padding: 80px 75px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-cta-section-05 .cta-wrap {
    padding: 70px 70px 70px;
  }
  .techwix-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-cta-section-05 .cta-wrap {
    padding: 70px 50px 70px;
  }
  .techwix-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 32px;
    line-height: 44px;
  }
}

.techwix-cta-section-06 {
  background: #010f31;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section-06 .cta-info {
    margin-top: 50px;
  }
}

.techwix-cta-section-06 .cta-info .cta-text {
  margin-left: 30px;
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section-06 .cta-info .cta-text {
    margin-left: 0;
  }
}

.techwix-cta-section-06 .cta-info .cta-text p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #ffffff;
}

.techwix-cta-section-06 .cta-info .cta-text .number {
  font-size: 36px;
  line-height: 42px;
  color: #086ad8;
}

.techwix-cta-section-07 {
  background: none;
}

.techwix-cta-section-07 .cta-wrap {
  background: #010f31;
  padding: 105px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -150px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .techwix-cta-section-07 .cta-info .cta-text {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-cta-section-07 .cta-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-cta-section-07 .cta-wrap {
    padding: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-cta-section-07 .cta-wrap {
    padding: 60px;
  }
  .techwix-cta-section-07 .cta-info .cta-text .number {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-cta-section-07 .cta-wrap {
    padding: 45px;
  }
}

/*--
/*  03 - About CSS
/*----------------------------------------*/
.techwix-about-section {
  background: #0c0520;
  padding-top: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.techwix-about-section .shape-1 {
  position: absolute;
  width: 100%;
  height: 130px;
  right: 0;
  left: 0px;
  top: -30px;
  background: #ffffff;
  transform: skewY(-3deg);
  transform-origin: 0;
  z-index: 2;
}

.techwix-about-section .about-img-wrap {
  position: relative;
}

.techwix-about-section .about-img-wrap .about-img img {
  border-radius: 10px;
}

.techwix-about-section .about-img-wrap .about-img.about-img-big {
  padding-top: 80px;
}

.techwix-about-section .about-img-wrap .about-img.about-img-sm {
  position: absolute;
  top: 0px;
  right: 0;
}

.techwix-about-section .about-img-wrap .about-img.about-img-sm .shape-01 {
  position: absolute;
  width: 9px;
  height: 235px;
  background: #086ad8;
  right: 10px;
  bottom: -270px;
}

.techwix-about-section .about-content-wrap {
  padding-left: 100px;
}

.techwix-about-section .about-content-wrap .section-title .title {
  color: #ffffff;
}

.techwix-about-section .about-content-wrap .section-title .sub-title {
  color: #ffffff;
}

.techwix-about-section .about-content-wrap p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 15px;
  margin-top: 20px;
}

.techwix-about-section .about-content-wrap .play-btn {
  margin-top: 60px;
}

.techwix-about-section .about-content-wrap .play-btn a {
  position: relative;
}

.techwix-about-section .about-content-wrap .play-btn a i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(240deg, #086ad8 0%, #42b9ff 100%);
  color: #ffffff;
  transition: all 0.3s linear;
}

.techwix-about-section .about-content-wrap .play-btn a span {
  font-size: 24px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #ffffff;
  margin-left: 30px;
}

.techwix-about-section .about-content-wrap .play-btn a::before {
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 0;
  left: 16%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
          animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.techwix-about-section .about-content-wrap .play-btn a::after {
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 0;
  left: 16%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
          animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.techwix-about-section .about-content-wrap .play-btn a:hover i {
  background: linear-gradient(180deg, #086ad8 0%, #42b9ff 100%);
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section {
    padding-top: 165px;
  }
  .techwix-about-section .shape-1 {
    top: -60px;
  }
  .techwix-about-section .about-img-wrap .about-img.about-img-sm {
    right: -25px;
  }
  .techwix-about-section .about-img-wrap .about-img.about-img-sm .shape-01 {
    height: 190px;
    bottom: -228px;
  }
  .techwix-about-section .about-content-wrap {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section {
    padding-top: 135px;
  }
  .techwix-about-section .about-content-wrap {
    padding-left: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section {
    padding-top: 150px;
  }
  .techwix-about-section .about-img-wrap .about-img.about-img-big {
    padding-top: 0px;
  }
  .techwix-about-section .about-img-wrap .about-img.about-img-sm {
    display: none;
  }
}

@-webkit-keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

@keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

.techwix-about-section-02 .about-02-left {
  padding: 0 35px;
}

.techwix-about-section-02 .about-02-left .section-title .title {
  font-size: 42px;
  line-height: 60px;
}

.techwix-about-section-02 .about-02-left .about-author {
  margin-top: 30px;
}

.techwix-about-section-02 .about-02-left .about-author .name {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.techwix-about-section-02 .about-02-left .about-author .designation {
  font-size: 12px;
  line-height: 18px;
  color: #4c4d56;
}

.techwix-about-section-02 .about-02-right {
  position: relative;
  padding-left: 112px;
}

.techwix-about-section-02 .about-02-right::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #e1e1e1;
  width: 1px;
  height: 350px;
}

.techwix-about-section-02 .about-02-right p {
  font-size: 16px;
  line-height: 30px;
  color: #4c4d56;
}

.about-list {
  margin-top: 40px;
}

.about-list ul li {
  font-size: 16px;
  line-height: 36px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: #333333;
}

.about-list ul li i {
  font-size: 16px;
  color: #086ad8;
  margin-right: 10px;
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-02 .about-02-right {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-02 .about-02-left {
    padding: 0 0px;
  }
  .techwix-about-section-02 .about-02-right {
    padding-left: 0px;
    margin-top: 50px;
  }
  .techwix-about-section-02 .about-02-right::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-about-section-02 .about-02-left .section-title .title {
    font-size: 36px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-02 .about-02-left .section-title .title {
    font-size: 30px;
    line-height: 48px;
  }
}

.techwix-about-section-03 {
  overflow: hidden;
  padding-bottom: 180px;
}

.techwix-about-section-03 .about-content-03 {
  padding-right: 145px;
}

.techwix-about-section-03 .about-content-03 .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.techwix-about-section-03 .about-content-03 .about-quote {
  background: #fff6f5;
  padding: 40px 30px;
  padding-right: 85px;
  border-radius: 5px;
  margin-top: 30px;
}

.techwix-about-section-03 .about-content-03 .about-quote .blockquote {
  padding-left: 60px;
  margin-bottom: 0;
  position: relative;
}

.techwix-about-section-03 .about-content-03 .about-quote .blockquote::before {
  content: '\f115';
  font-family: flaticon !important;
  font-size: 40px;
  line-height: 1;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ff5538;
}

.techwix-about-section-03 .about-content-03 .about-quote .blockquote p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #0e0e0e;
}

.techwix-about-section-03 .about-content-03 .about-list-02 {
  padding-top: 20px;
  padding-right: 25px;
}

.techwix-about-section-03 .about-content-03 .about-list-02 .about-list-item-02 {
  margin-top: 20px;
}

.techwix-about-section-03 .about-content-03 .about-list-02 .about-list-item-02 .title {
  font-size: 18px;
  line-height: 30px;
  color: #231e32;
}

.techwix-about-section-03 .about-content-03 .about-list-02 .about-list-item-02 .title i {
  color: #ff5538;
  margin-right: 7px;
}

.techwix-about-section-03 .about-content-03 .about-list-02 .about-list-item-02 P {
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
}

.techwix-about-section-03 .about-img-warp-3 {
  position: relative;
  z-index: 1;
}

.techwix-about-section-03 .about-img-warp-3 .shape-1 {
  width: 520px;
  height: 490px;
  background: #ff5538;
  position: absolute;
  left: 85px;
  bottom: -30px;
  z-index: -1;
}

.techwix-about-section-03 .about-img-warp-3 .about-img img {
  border-radius: 10px;
}

.techwix-about-section-03 .about-img-warp-3 .about-img-big {
  text-align: right;
}

.techwix-about-section-03 .about-img-warp-3 .about-img-sm {
  position: absolute;
  left: 0px;
  bottom: -65px;
}

@media only screen and (max-width: 1399px) {
  .techwix-about-section-03 .about-content-03 {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-03 {
    padding-bottom: 100px;
  }
  .techwix-about-section-03 .about-content-03 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-03 {
    padding-bottom: 180px;
  }
  .techwix-about-section-03 .about-content-03 {
    padding-right: 140px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-about-section-03 {
    padding-bottom: 150px;
  }
  .techwix-about-section-03 .about-content-03 {
    padding-right: 0px;
  }
  .techwix-about-section-03 .about-img-warp-3 .shape-1 {
    left: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-03 {
    padding-bottom: 70px;
  }
  .techwix-about-section-03 .about-content-03 .about-quote {
    padding-right: 35px;
  }
  .techwix-about-section-03 .about-img-warp-3 .shape-1 {
    display: none;
  }
  .techwix-about-section-03 .about-img-warp-3 .about-img-big {
    text-align: center;
  }
  .techwix-about-section-03 .about-img-warp-3 .about-img-sm {
    display: none;
  }
}

.techwix-about-section-04 .about-content-wrap {
  padding-right: 105px;
}

.techwix-about-section-04 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 30px;
}

.techwix-about-section-04 .about-img-wrap {
  position: relative;
}

.techwix-about-section-04 .about-img-wrap .about-img img {
  border-radius: 10px;
}

.techwix-about-section-04 .about-img-wrap .about-img-big {
  text-align: right;
}

.techwix-about-section-04 .about-img-wrap .about-img-sm {
  position: absolute;
  left: -15px;
  bottom: 35px;
  border: 8px solid #ffffff;
  border-radius: 10px;
}

.about-author-info-wrap {
  display: flex;
  align-items: center;
  border-top: 2px solid #ececec;
  margin-top: 30px;
  padding-top: 30px;
}

.about-author-info-wrap .about-author .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.about-author-info-wrap .about-author .designation {
  font-size: 12px;
  line-height: 18px;
}

.about-author-info-wrap .about-info {
  flex: 1;
  padding-left: 50px;
  margin-left: 30px;
  position: relative;
}

.about-author-info-wrap .about-info::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ececec;
  width: 2px;
  height: 95px;
  transform: translateY(-50%);
}

.about-author-info-wrap .about-info p {
  font-size: 16px;
  line-height: 30px;
  color: #0e0e0e;
}

.about-author-info-wrap .about-info .number {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #086ad8;
}

@media only screen and (max-width: 449px) {
  .about-author-info-wrap .about-info {
    padding-left: 20px;
    margin-left: 15px;
  }
  .about-author-info-wrap .about-info p {
    font-size: 15px;
  }
}

.play-btn-02 {
  position: absolute;
  left: 15%;
  top: 16%;
}

.play-btn-02 a {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(240deg, #086ad8 0%, #42b9ff 100%);
  color: #ffffff;
  transition: all 0.3s linear;
}

.play-btn-02 a::before {
  content: "";
  border: 2px solid #086ad8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
          animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.play-btn-02 a::after {
  content: "";
  border: 2px solid #086ad8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
          animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.play-btn-02 a:hover {
  background: linear-gradient(180deg, #086ad8 0%, #42b9ff 100%);
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-04 .about-content-wrap {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-04 .about-img-wrap {
    margin-top: 60px;
  }
  .techwix-about-section-04 .play-btn-02 {
    left: 22%;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-about-section-04 .play-btn-02 {
    left: 2%;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-04 .about-content-wrap {
    padding-right: 0px;
  }
  .techwix-about-section-04 .about-img-wrap {
    position: relative;
  }
  .techwix-about-section-04 .about-img-wrap .about-img-big {
    text-align: center;
  }
  .techwix-about-section-04 .about-img-wrap .about-img-sm {
    display: none;
  }
  .techwix-about-section-04 .play-btn-02 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.techwix-about-section-05 .section-title {
  max-width: 1060px;
  margin: 0 auto;
}

.techwix-about-section-05 .about-img-wrap {
  position: relative;
}

.techwix-about-section-05 .about-img-wrap .about-img img {
  border-radius: 15px;
}

.techwix-about-section-05 .about-img-wrap .about-img-big {
  text-align: right;
}

.techwix-about-section-05 .about-img-wrap .about-img-sm {
  position: absolute;
  top: 80px;
  left: 0;
}

.techwix-about-section-05 .about-content-wrap {
  padding-top: 100px;
}

.techwix-about-section-05 .about-content {
  padding-left: 120px;
}

.techwix-about-section-05 .about-content .title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  color: #0e0e0e;
}

.techwix-about-section-05 .about-content .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 30px;
}

.techwix-about-section-05 .about-content .about-list-item-wrap {
  margin-top: -20px;
}

.techwix-about-section-05 .about-content .about-list-item-wrap .about-list-item {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.techwix-about-section-05 .about-content .about-list-item-wrap .about-list-item .about-text {
  flex: 1;
  margin-left: 20px;
}

.techwix-about-section-05 .about-content .about-list-item-wrap .about-list-item .about-text .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.techwix-about-section-05 .about-content .about-list-item-wrap .about-list-item .about-text p {
  font-size: 14px;
  line-height: 27px;
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-05 .about-content-wrap {
    padding-top: 80px;
  }
  .techwix-about-section-05 .about-content {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-05 .about-content-wrap {
    padding-top: 60px;
  }
  .techwix-about-section-05 .about-img-wrap .about-img-big {
    text-align: center;
  }
  .techwix-about-section-05 .about-content {
    padding-left: 0px;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-05 .about-img-wrap .about-img-sm {
    display: none;
  }
}

.techwix-about-section-06 {
  padding-bottom: 200px;
}

.techwix-about-section-06 .about-img {
  position: relative;
  margin-top: 70px;
  z-index: 1;
}

.techwix-about-section-06 .about-img .shape-1 {
  position: absolute;
  left: 0;
  top: -25%;
  -webkit-animation: round-01 7s linear infinite;
          animation: round-01 7s linear infinite;
  z-index: -1;
}

.techwix-about-section-06 .about-img .play-btn {
  position: absolute;
  top: -25px;
  right: 135px;
}

.techwix-about-section-06 .about-img .play-btn a {
  width: 80px;
  height: 80px;
  line-height: 68px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 7px solid #ffffff;
  background: #11ddf5;
  color: #ffffff;
}

.techwix-about-section-06 .about-img .play-btn a:hover {
  background: #0f0948;
  color: #ffffff;
}

.techwix-about-section-06 .about-img .image {
  padding-left: 70px;
}

.techwix-about-section-06 .about-img .image img {
  border-radius: 50%;
}

.techwix-about-section-06 .about-content-wrap {
  padding-right: 115px;
}

.techwix-about-section-06 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap {
  padding-top: 50px;
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li:last-child {
  margin-bottom: 0;
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li a {
  font-size: 20px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #0e0e0e;
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li a {
    font-size: 18px;
  }
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li a:hover {
  color: #11ddf5;
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li a:hover i {
  transform: translateX(20px);
}

.techwix-about-section-06 .about-content-wrap .about-list-wrap .about-list li a i {
  transform: translateX(15px);
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-06 {
    padding-bottom: 100px;
  }
  .techwix-about-section-06 .about-img .shape-1 {
    top: -13%;
  }
  .techwix-about-section-06 .about-img .image {
    padding-left: 30px;
  }
  .techwix-about-section-06 .about-content-wrap {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-06 .about-img .shape-1 {
    top: -25%;
    left: 11%;
  }
  .techwix-about-section-06 .about-img .image {
    text-align: center;
    padding-left: 0px;
  }
  .techwix-about-section-06 .about-content-wrap {
    margin-top: 150px;
  }
  .techwix-about-section-06 .about-content-wrap .about-list-wrap {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-about-section-06 .about-img .shape-1 {
    top: -20%;
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-06 .about-img .shape-1 {
    top: -10%;
  }
  .techwix-about-section-06 .about-content-wrap {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-about-section-06 .about-content-wrap {
    margin-top: 80px;
  }
}

.techwix-about-section-07 .about-img-wrap {
  display: flex;
  position: relative;
  z-index: 1;
}

.techwix-about-section-07 .about-img-wrap .shape-1 {
  position: absolute;
  right: 55px;
  bottom: -30px;
  z-index: -1;
}

.techwix-about-section-07 .about-img-wrap .about-img {
  overflow: hidden;
}

.techwix-about-section-07 .about-img-wrap .about-img img {
  border-radius: 10px;
}

.techwix-about-section-07 .about-img-wrap .about-img:first-child {
  padding-right: 15px;
}

.techwix-about-section-07 .about-img-wrap .about-img:last-child {
  padding-left: 15px;
  margin-top: 75px;
}

.techwix-about-section-07 .about-content-wrap {
  padding-left: 90px;
}

.techwix-about-section-07 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.techwix-about-section-07 .about-content-wrap .about-list-03 {
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
  padding-top: 15px;
}

.techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
  padding-right: 40px;
  margin-top: 25px;
}

.techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #231e32;
}

.techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 p {
  font-size: 16px;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .techwix-about-section-07 .about-img-wrap .shape-1 {
    right: 10px;
    bottom: -30px;
    width: 370px;
  }
  .techwix-about-section-07 .about-content-wrap {
    padding-left: 40px;
  }
  .techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-about-section-07 .about-img-wrap {
    justify-content: center;
  }
  .techwix-about-section-07 .about-img-wrap .shape-1 {
    right: 75px;
    width: inherit;
  }
  .techwix-about-section-07 .about-content-wrap {
    padding-left: 0px;
    padding-top: 60px;
  }
  .techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-about-section-07 .about-img-wrap .shape-1 {
    right: 40px;
    width: 370px;
  }
  .techwix-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-about-section-07 .about-img-wrap .shape-1 {
    display: none;
  }
}

/*--
/*  03 - Counter CSS
/*----------------------------------------*/
.techwix-counter-section {
  background: #e5effa;
  padding-top: 60px;
  padding-bottom: 80px;
}

.techwix-counter-section .counter-wrap {
  margin-top: -30px;
}

.single-counter {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.single-counter .counter-img {
  height: 95px;
  width: 95px;
  line-height: 95px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
}

.single-counter .counter-content {
  margin-left: 20px;
}

.single-counter .counter-content span {
  font-size: 40px;
  line-height: 26px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #086ad8;
}

.single-counter .counter-content p {
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #4c4d56;
}

.techwix-counter-section-02 .counter-wrap {
  border: 1px solid #e1e1e1;
  background: #ffffff;
  margin-bottom: -120px;
  position: relative;
  z-index: 5;
  padding: 15px 25px 75px;
}

.techwix-counter-section-02 .single-counter {
  position: relative;
  margin-left: 30px;
}

.techwix-counter-section-02 .single-counter::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  background: #e1e1e1;
  width: 1px;
  height: 220px;
  transform: translateY(-44%);
}

.techwix-counter-section-02 .single-counter.single-counter-4::before {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .techwix-counter-section-02 .counter-wrap {
    padding: 15px 55px 75px;
  }
  .techwix-counter-section-02 .single-counter {
    margin-left: 0px;
  }
  .techwix-counter-section-02 .single-counter::before {
    display: none;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-counter-section-02 .counter-wrap {
    padding: 15px 28px 75px;
  }
}

.techwix-counter-section-03 {
  background: #010642;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 80px;
  padding-bottom: 110px;
  position: relative;
  z-index: 1;
}

.techwix-counter-section-03::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(1, 1, 63, 0.9);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.single-counter-02 {
  margin-top: 30px;
}

.single-counter-02 span {
  font-size: 50px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #086ad8;
}

.single-counter-02 p {
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
  color: #ffffff;
}

.techwix-counter-section-04 {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}

.techwix-counter-section-04::before {
  display: none;
}

.techwix-counter-section-04 .counter-wrap {
  background: linear-gradient(55deg, #693eaa 25%, #47bbff 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: -110px;
  padding-top: 65px;
  padding-bottom: 95px;
}

.techwix-counter-section-04 .counter-wrap .single-counter-02 span {
  color: #ffffff;
}

/*--
/*  03 - Case Study CSS
/*----------------------------------------*/
.case-study-content-wrap {
  padding-top: 65px;
}

.single-case-study {
  position: relative;
}

.single-case-study .case-study-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.single-case-study .case-study-img a {
  display: block;
}

.single-case-study .case-study-img a::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #FFFFFF00 25%, #00000082 63%);
  z-index: 0;
}

.single-case-study .case-study-img .top-meta {
  position: absolute;
  top: 35px;
  left: 30px;
  height: 40px;
  line-height: 38px;
  padding: 0 15px;
  border-radius: 5px;
  background: #ffffff;
}

.single-case-study .case-study-content {
  position: absolute;
  bottom: 35px;
  left: 30px;
}

.single-case-study .case-study-content .title {
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}

.single-case-study .case-study-content p {
  font-size: 16px;
  line-height: 24px;
  color: #086ad8;
}

.single-case-study:hover .case-study-content .title {
  color: #086ad8;
}

.case-study-active .swiper-pagination {
  position: relative;
  margin-top: 50px;
}

.case-study-active .swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px;
}

.techwix-case-study-section-02 {
  background: #010f31;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-case-study-section-02 .single-case-study-02 {
  position: relative;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-img {
  position: relative;
  overflow: hidden;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-img a {
  display: block;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-img a::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(21deg, #086ad8 22%, rgba(146, 152, 197, 0.17409) 40%);
  z-index: 0;
  display: none;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-content {
  position: absolute;
  bottom: 60px;
  left: 60px;
  max-width: 285px;
  display: none;
}

@media only screen and (max-width: 449px) {
  .techwix-case-study-section-02 .single-case-study-02 .case-study-content {
    bottom: 10px;
    left: 25px;
  }
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-content .sub-title {
  font-size: 14px;
  line-height: 27px;
  font-weight: 500;
  color: #ffffff;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-content .title {
  font-size: 24px;
  line-height: 27px;
  color: #0e0e0e;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-content .title span {
  display: inline-block;
  background: #ffffff;
  margin-top: 3px;
  padding: 0 5px;
}

.techwix-case-study-section-02 .single-case-study-02 .case-study-content .text {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 20px;
}

.techwix-case-study-section-02 .single-case-study-02:hover .case-study-content .title {
  color: #086ad8;
}

.techwix-case-study-section-02 .swiper-slide.swiper-slide-active .single-case-study-02 .case-study-img a::before {
  display: block;
}

.techwix-case-study-section-02 .swiper-slide.swiper-slide-active .single-case-study-02 .case-study-content {
  display: block;
}

/*--
/*  03 - Testimonial CSS
/*----------------------------------------*/
.testimonial-wrap {
  height: 530px;
  background: #f0f0f0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  padding-top: 75px;
  padding-bottom: 90px;
  padding-left: 75px;
  padding-right: 65px;
}

.testimonial-wrap .testimonial-img {
  position: relative;
  z-index: 1;
}

.testimonial-wrap .testimonial-img .shape-1 {
  position: absolute;
  top: 20px;
  left: 20px;
  -webkit-animation: round-01 7s linear infinite;
          animation: round-01 7s linear infinite;
  z-index: -1;
}

.testimonial-wrap .testimonial-img img {
  position: relative;
  bottom: 45px;
}

.testimonial-wrap .testimonial-content-wrap {
  max-width: 520px;
  background: #ffffff;
  padding: 50px 50px;
  padding-bottom: 60px;
  position: relative;
}

.testimonial-wrap .testimonial-content-wrap::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 35px solid transparent;
  border-top: 40px solid #ffffff;
  left: -50px;
  bottom: 215px;
  z-index: 1;
  transform: rotate(90deg);
}

.single-testimonial p {
  font-size: 26px;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.single-testimonial .name {
  font-size: 16px;
  line-height: 20px;
  color: #0e0e0e;
}

.single-testimonial .designation {
  font-size: 12px;
  line-height: 20px;
  color: #47484a;
}

@media only screen and (max-width: 1199px) {
  .testimonial-wrap .testimonial-img .shape-1 {
    left: -15px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap {
    height: auto;
    padding-top: 90px;
  }
  .testimonial-wrap .testimonial-img {
    text-align: center;
  }
  .testimonial-wrap .testimonial-img .shape-1 {
    left: 75px;
  }
  .testimonial-wrap .testimonial-content-wrap {
    margin: 0 auto;
    margin-top: 30px;
  }
  .testimonial-wrap .testimonial-content-wrap::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap {
    padding-bottom: 70px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .testimonial-wrap .testimonial-img .shape-1 {
    left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
  .testimonial-wrap .testimonial-content-wrap {
    padding: 50px 30px;
  }
}

.techwix-testimonial-section-02 {
  background: #ff5538;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-testimonial-section-02 .testimonial-content-wrap-02 {
  margin-top: 60px;
}

.techwix-testimonial-section-02 .single-testimonial-02 {
  background: #ffffff;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  width: 570px;
  margin: 0 40px;
  position: relative;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-thumb {
  position: absolute;
  left: -7%;
  top: 50%;
  transform: translateY(-65%);
  z-index: 3;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-thumb img {
  border-radius: 10px;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-content {
  padding: 40px 130px;
  padding-right: 40px;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-content p {
  font-size: 20px;
  line-height: 32px;
  color: #4c4d56;
  margin-top: 20px;
  margin-bottom: 15px;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-content .name {
  font-size: 16px;
  line-height: 20px;
  color: #0e0e0e;
}

.techwix-testimonial-section-02 .single-testimonial-02 .testimonial-content .designation {
  font-size: 12px;
  line-height: 20px;
  color: #47484a;
}

.testimonial-02-active .swiper-pagination {
  position: relative;
  margin-top: 50px;
}

.testimonial-02-active .swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px;
}

.testimonial-02-active .swiper-pagination .swiper-pagination-bullet::after {
  border: 1px solid #ffffff;
}

.testimonial-02-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffffff;
}

@media only screen and (max-width: 1399px) {
  .techwix-testimonial-section-02 .single-testimonial-02 {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-testimonial-section-02 .single-testimonial-02 {
    width: 100%;
    text-align: center;
  }
  .techwix-testimonial-section-02 .single-testimonial-02 .testimonial-thumb {
    position: relative;
    text-align: center;
    left: 0;
    top: 0;
    padding-top: 40px;
    transform: translateY(0%);
  }
  .techwix-testimonial-section-02 .single-testimonial-02 .testimonial-content {
    padding: 40px 45px;
  }
}

.techwix-testimonial-section-03 {
  background: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-testimonial-section-03 .single-testimonial-02 {
  margin: 10px 40px;
}

@media only screen and (max-width: 1399px) {
  .techwix-testimonial-section-03 .single-testimonial-02 {
    margin: 0 auto;
  }
}

.techwix-testimonial-section-03 .single-testimonial-02 .testimonial-thumb::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #086ad8;
  width: 135px;
  height: 135px;
  border-radius: 10px;
  transform: translate(5px, 5px);
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .techwix-testimonial-section-03 .single-testimonial-02 .testimonial-thumb::before {
    left: 50%;
    top: 50%;
    transform: translate(-45%, -31%);
  }
}

.techwix-testimonial-section-03 .testimonial-02-active .swiper-pagination .swiper-pagination-bullet {
  background-color: #e8e8e8;
}

.techwix-testimonial-section-03 .testimonial-02-active .swiper-pagination .swiper-pagination-bullet::after {
  border: 1px solid #57585a;
}

.techwix-testimonial-section-03 .testimonial-02-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #086ad8;
}

.techwix-testimonial-section-04 {
  background: #010f31;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-testimonial-section-04 .single-testimonial-02 {
  background: rgba(255, 255, 255, 0.1);
}

.techwix-testimonial-section-04 .single-testimonial-02 .testimonial-content p {
  color: #ffffff;
}

.techwix-testimonial-section-04 .single-testimonial-02 .testimonial-content .name {
  color: #ffffff;
}

.techwix-testimonial-section-04 .single-testimonial-02 .testimonial-content .designation {
  color: #ffffff;
}

.techwix-testimonial-section-05 {
  padding-top: 260px;
}

/*--
/*  13 - Brand CSS
/*----------------------------------------*/
.brand-title {
  font-size: 26px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  color: #4c4d56;
  margin-top: -7px;
}

@media only screen and (max-width: 575px) {
  .brand-title {
    font-size: 20px;
  }
}

.brand-title span {
  color: #086ad8;
  font-weight: 700;
}

.brand-active {
  max-width: 1070px;
  width: 100%;
  margin: 45px auto 0;
}

.techwix-brand-section-02 .brand-wrapper {
  border-top: 1px solid #e1e1e1;
  padding-top: 20px;
  padding-bottom: 120px;
}

.techwix-brand-section-03 .brand-wrapper {
  padding-top: 90px;
}

.techwix-brand-section-03 .brand-active {
  margin-top: 0;
}

.techwix-brand-section-04 .brand-wrapper {
  padding-top: 0;
}

/*--
/*  03 - Skill CSS
/*----------------------------------------*/
.techwix-skill-section .skill-left {
  padding-right: 120px;
}

.techwix-skill-section .skill-left p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
}

.techwix-skill-section .skill-left .skill-author {
  margin-top: 30px;
}

.techwix-skill-section .skill-left .skill-author .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.techwix-skill-section .skill-left .skill-author .designation {
  font-size: 12px;
  line-height: 18px;
}

.techwix-skill-section .skill-right {
  padding-right: 50px;
  margin-top: 30px;
}

.techwix-skill-section .skill-right .experience {
  display: flex;
}

.techwix-skill-section .skill-right .experience .number {
  font-size: 180px;
  line-height: 30px;
  color: #ff5538;
  margin-right: 10px;
}

.techwix-skill-section .skill-right .experience span {
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  margin-top: 5px;
}

.counter-bar {
  padding-top: 20px;
}

.counter-bar .skill-item {
  margin-top: 30px;
}

.counter-bar .skill-item .title {
  font-size: 14px;
  line-height: 45px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #120e1d;
}

.counter-bar .skill-item .skill-bar .bar-inner {
  width: 100%;
  height: 6px;
  border-radius: 0px;
  background-color: transparent;
  position: relative;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 0px;
  background: #ff5538;
  transition: 3s;
  transition-delay: 1s;
  width: 0;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
  position: absolute;
  top: -45px;
  right: -30px;
  font-size: 12px;
  color: #ffffff;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::after {
  content: "";
  position: absolute;
  left: -7px;
  top: -2px;
  width: 40px;
  height: 25px;
  background: #0e0e0e;
  line-height: 20px;
  text-align: center;
  z-index: -1;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::before {
  position: absolute;
  content: "";
  border-top: 20px solid #0e0e0e;
  width: 0;
  height: 0;
  bottom: -12px;
  left: 0;
  border-right: 15px solid transparent;
  transform: translateX(-50%);
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .techwix-skill-section .skill-left {
    padding-right: 30px;
  }
  .techwix-skill-section .skill-right .experience .number {
    font-size: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-skill-section .skill-left {
    padding-right: 0px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-skill-section .skill-right {
    padding-right: 0px;
  }
  .techwix-skill-section .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
    right: -15px;
  }
}

.techwix-skill-section-02 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.techwix-skill-section-02 .skill-left {
  padding-right: 25px;
}

.techwix-skill-section-02 .skill-left .experience-wrap {
  display: flex;
  margin-top: 35px;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience {
  max-width: 170px;
  background: linear-gradient(-120deg, #43baff 0%, #7141b1 100%);
  padding: 30px;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience .number {
  font-size: 100px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience span {
  font-size: 18px;
  line-height: 24px;
  margin-top: 30px;
  display: inline-block;
  color: #ffffff;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience-text {
  flex: 1;
  margin-left: 55px;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience-text p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 0;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more {
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #086ad8;
  margin-top: 20px;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.techwix-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more:hover i {
  margin-left: 5px;
}

.techwix-skill-section-02 .skill-right {
  margin-top: 0;
}

.techwix-skill-section-02 .skill-right .counter-bar {
  padding-top: 0px;
}

.techwix-skill-section-02 .skill-right .counter-bar .skill-item .skill-bar .bar-inner .progress-line {
  background: #086ad8;
}

@media only screen and (max-width: 1199px) {
  .techwix-skill-section-02 .skill-left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-skill-section-02 .skill-right {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-skill-section-02 .skill-left .experience-wrap {
    flex-direction: column;
    gap: 25px;
  }
  .techwix-skill-section-02 .skill-left .experience-wrap .experience-text {
    margin-left: 0px;
  }
}

.techwix-skill-section-03 .skill-left {
  padding-right: 60px;
}

.techwix-skill-section-03 .skill-left .about-list {
  margin-top: 20px;
}

.techwix-skill-section-03 .skill-left .about-author-info-wrap {
  margin-right: 30px;
}

.techwix-skill-section-03 .skill-right {
  padding-right: 120px;
}

.techwix-skill-section-03 .skill-right .text {
  font-size: 18px;
  line-height: 30px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .techwix-skill-section-03 .skill-left {
    padding-right: 30px;
  }
  .techwix-skill-section-03 .skill-right {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-skill-section-03 .skill-left {
    padding-right: 0px;
  }
  .techwix-skill-section-03 .skill-left .about-author-info-wrap {
    margin-right: 0px;
  }
}

/*--
/*  19 - Contact CSS
/*----------------------------------------*/
.techwix-contact-section .contact-wrap {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 60% 100%;
}

.techwix-contact-section .contact-wrap .contact-info .section-title .title {
  position: relative;
  padding-left: 45px;
}

.techwix-contact-section .contact-wrap .contact-info .section-title .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff5538;
  height: 150px;
  width: 4px;
  transform: translateY(-50%);
}

.techwix-contact-section .contact-wrap .contact-info ul {
  padding-top: 15px;
}

.techwix-contact-section .contact-wrap .contact-info ul li {
  margin-top: 15px;
  padding-right: 65px;
}

.techwix-contact-section .contact-wrap .contact-info ul li:first-child {
  border-top: 0;
}

.techwix-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
  font-size: 50px;
  color: #ff5538;
}

@media only screen and (max-width: 575px) {
  .techwix-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
    font-size: 42px;
  }
}

.techwix-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text {
  margin-left: 20px;
}

.techwix-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text .title {
  font-size: 24px;
  line-height: 30px;
  color: #0e0e0e;
}

.techwix-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text p {
  font-size: 16px;
  line-height: 30px;
  color: #0e0e0e;
}

.techwix-contact-section .contact-wrap .contact-form {
  background: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 110px;
}

@media only screen and (max-width: 1399px) {
  .techwix-contact-section .contact-wrap .contact-form {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-contact-section .contact-wrap .contact-form {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-contact-section .contact-wrap .contact-form {
    margin-left: 0;
    margin-top: 60px;
  }
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap {
  padding: 55px 40px;
}

@media only screen and (max-width: 1199px) {
  .techwix-contact-section .contact-wrap .contact-form .contact-form-wrap {
    padding: 40px;
  }
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap {
  margin-bottom: 30px;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff5538;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
  font-size: 42px;
  line-height: 54px;
  font-weight: 600;
  color: #333333;
}

@media only screen and (max-width: 1199px) {
  .techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form {
  margin-top: 25px;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
  height: 55px;
  line-height: 55px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea {
  height: 135px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
  resize: none;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn {
  margin-top: 25px;
}

.techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
  font-size: 16px;
  width: 100%;
  height: 55px;
  line-height: 50px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .techwix-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
    padding: 0 45px;
  }
}

.techwix-contact-section-02 .contact-wrap .contact-info .section-title .title::before {
  background: #086ad8;
}

.techwix-contact-section-02 .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
  color: #086ad8;
}

.techwix-contact-section-02 .contact-wrap .contact-form {
  background: #ffffff;
  box-shadow: 0px 0px 139px 0px rgba(0, 0, 0, 0.1);
}

.techwix-contact-section-02 .contact-wrap .contact-form .contact-form-wrap .heading-wrap .sub-title {
  color: #086ad8;
}

.contact-info-section .contact-info-wrap {
  margin-top: -30px;
}

.contact-info-section .contact-info-wrap .single-contact-info {
  background: #f8f8f8;
  padding: 50px 80px 75px;
  border-radius: 5px;
  margin-top: 30px;
}

@media only screen and (max-width: 1199px) {
  .contact-info-section .contact-info-wrap .single-contact-info {
    padding: 50px 40px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-section .contact-info-wrap .single-contact-info {
    padding: 50px 30px 60px;
  }
}

.contact-info-section .contact-info-wrap .single-contact-info .info-content .title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.contact-info-section .contact-info-wrap .single-contact-info .info-content p {
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .contact-info-section .contact-info-wrap .single-contact-info .info-content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-info-section .contact-info-wrap .single-contact-info .info-content p {
    font-size: 18px;
  }
}

.techwix-contact-section-03 .contact-wrap {
  margin-bottom: -60px;
  position: relative;
  z-index: 5;
}

.techwix-contact-section-03 .contact-wrap .contact-form .contact-form-wrap .form-btn {
  text-align: center;
}

.techwix-contact-section-03 .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
  width: 70%;
}

.contact-map-section .contact-map-wrap {
  margin-bottom: -8px;
}

.contact-map-section .contact-map-wrap iframe {
  height: 550px;
  width: 100%;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
}

/*--
/*  03 - Subscribe CSS
/*----------------------------------------*/
.techwix-subscribe-section .subscribe-wrap {
  background: #ff5538;
  border-radius: 10px;
  padding: 40px 110px;
  padding-left: 40px;
  margin-bottom: -75px;
  position: relative;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-content {
  display: flex;
  align-items: center;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-content .subscribe-icon {
  width: 115px;
  height: 115px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 35px;
  background: #0e0e0e;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-content .title {
  flex: 1;
  font-size: 34px;
  line-height: 36px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 160px;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form {
  position: relative;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
  height: 60px;
  line-height: 60px;
  border: 0;
  padding-right: 170px;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-weight: 400;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-weight: 400;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-weight: 400;
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 18px;
  color: #93a1a2;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    padding-right: 20px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
    font-size: 16px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
    font-size: 16px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
    font-size: 16px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
    font-size: 16px;
  }
}

.techwix-subscribe-section .subscribe-wrap .subscribe-form form button {
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: #0e0e0e;
  color: #ffffff;
  padding: 0 40px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form button {
    height: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-subscribe-section .subscribe-wrap .subscribe-form form button {
    position: relative;
    width: 100%;
    display: block;
    margin-top: 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-subscribe-section .subscribe-wrap .subscribe-content .subscribe-icon {
    width: 105px;
    height: 105px;
    line-height: 105px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-content .title {
    padding-left: 90px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-subscribe-section .subscribe-wrap {
    text-align: center;
    padding: 40px 90px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-content .subscribe-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-content .title {
    padding-left: 0px;
    margin-top: 40px;
  }
  .techwix-subscribe-section .subscribe-wrap .subscribe-form {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-subscribe-section .subscribe-wrap {
    text-align: center;
    padding: 40px 30px;
  }
}

/*--
/*  03 - Choose Us CSS
/*----------------------------------------*/
.techwix-choose-us-section {
  background: #f8f8f8;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 210px;
}

.techwix-choose-us-section .section-title {
  max-width: 570px;
  margin: 0 auto;
}

.choose-us-content-wrap {
  padding-top: 30px;
}

.choose-us-content-wrap .more-choose-content {
  margin-top: 30px;
}

.choose-us-content-wrap .more-choose-content p {
  font-size: 16px;
  line-height: 30px;
}

.choose-us-content-wrap .more-choose-content p a {
  font-weight: 600;
  color: #086ad8;
}

.choose-us-content-wrap .more-choose-content p a i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.choose-us-content-wrap .more-choose-content p a:hover i {
  margin-left: 5px;
}

.choose-us-item {
  margin-top: 30px;
}

.choose-us-item .choose-us-img {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.choose-us-item .choose-us-img a {
  display: block;
  position: relative;
}

.choose-us-item .choose-us-img a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(249, 80, 0, 0) 35%, rgba(14, 14, 14, 0.92) 78%);
  width: 100%;
  height: 100%;
  z-index: 0;
}

.choose-us-item .choose-us-img .choose-us-content {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 60px 60px;
  padding-right: 75px;
}

.choose-us-item .choose-us-img .choose-us-content .title {
  font-size: 24px;
  line-height: 27px;
  color: #ffffff;
  padding-left: 15px;
  position: relative;
}

.choose-us-item .choose-us-img .choose-us-content .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 3px;
  height: 50px;
  transform: translateY(-50%);
}

.choose-us-item .choose-us-img .choose-us-content p {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: -115px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.choose-us-item:hover .choose-us-img .choose-us-content p {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1399px) {
  .choose-us-item .choose-us-img .choose-us-content {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .choose-us-item .choose-us-img .choose-us-content {
    padding: 40px 30px;
    padding-right: 25px;
  }
}

.techwix-choose-us-section-02 {
  background: #f8f8f8;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 400px;
  z-index: 1;
}

.techwix-choose-us-section-02 .choose-us-left {
  padding-right: 50px;
}

.techwix-choose-us-section-02 .choose-us-left .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
  padding-right: 65px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-right: 40px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .author-wrap .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .author-wrap .designation {
  font-size: 12px;
  line-height: 18px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap {
  flex: 1;
  padding-left: 50px;
  margin-left: 30px;
  position: relative;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ececec;
  width: 2px;
  height: 95px;
  transform: translateY(-50%);
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap .counter-bar {
  padding-top: 0px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap .counter-bar .skill-item {
  margin-top: 15px;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap .counter-bar .skill-item.skill-item-01 {
  margin-top: 0;
}

.techwix-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap .counter-bar .skill-item .skill-bar .bar-inner .progress-line {
  background: #086ad8;
}

.techwix-choose-us-section-02 .choose-us-right {
  padding-left: 10px;
}

@media only screen and (max-width: 991px) {
  .techwix-choose-us-section-02 .choose-us-right .faq-accordion {
    margin-top: 50px;
  }
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item {
  border: 0;
  box-shadow: 0px 0px 161px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 35px;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button {
  padding: 25px 35px;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button .title {
  font-size: 22px;
  color: #0e0e0e;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button::after {
  position: absolute;
  content: "+";
  border: none;
  width: 30px;
  height: 30px;
  line-height: 24px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background: linear-gradient(-140deg, #43baff 0%, #7141b1 100%);
  right: 35px;
  transition: all .3s ease-out 0s;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-body {
  font-size: 14px;
  border-top: 1px solid #ebebeb;
  padding: 30px 35px;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  box-shadow: none;
}

.techwix-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:not(.collapsed)::after {
  position: absolute;
  content: "-";
  border: none;
  width: 30px;
  height: 30px;
  line-height: 24px;
  text-align: center;
  background: #e6eef8;
  color: #0e0e0e;
  transform: rotate(0);
}

@media only screen and (max-width: 1199px) {
  .techwix-choose-us-section-02 {
    padding-bottom: 350px;
  }
  .techwix-choose-us-section-02 .choose-us-left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-choose-us-section-02 {
    padding-bottom: 330px;
  }
  .techwix-choose-us-section-02 .choose-us-right {
    padding-left: 0;
    padding-top: 15px;
  }
}

.choose-us-item-02 {
  background: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px 45px 40px;
  margin-top: 30px;
  position: relative;
}

.choose-us-item-02 .choose-us-img img {
  transition: all 0.3s linear;
}

.choose-us-item-02 .choose-us-content .title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 20px;
}

.choose-us-item-02 .choose-us-content p {
  font-size: 16px;
  line-height: 30px;
  padding-top: 20px;
}

.choose-us-item-02:hover .choose-us-img img {
  transform: scale(0.9);
}

.techwix-choose-us-section-03 {
  background: #ffffff;
  padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
  .techwix-choose-us-section-03 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-choose-us-section-03 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-choose-us-section-03 {
    padding-top: 60px;
  }
}

.techwix-choose-us-section-04 {
  background: #ffffff;
  padding-top: 120px;
}

.techwix-choose-us-section-04 .choose-us-wrap {
  margin-top: -30px;
}

.techwix-choose-us-section-04 .choose-us-content-wrap {
  padding-top: 0;
}

.techwix-choose-us-section-04 .choose-us-item-02 {
  border: 1px solid #e1e1e1;
  box-shadow: none;
}

/*--
/*  13 - Team CSS
/*----------------------------------------*/
.team-content-wrap {
  padding-top: 70px;
}

.single-team {
  position: relative;
}

.single-team .team-img a {
  position: relative;
  display: block;
}

.single-team .team-img a::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(249, 80, 0, 0) 35%, rgba(14, 14, 14, 0.89) 78%);
  z-index: 0;
  transition: all 0.5s linear;
}

.single-team .team-content {
  position: absolute;
  left: 60px;
  bottom: 40px;
  z-index: 5;
  transition: all 0.3s linear;
}

.single-team .team-content .name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.single-team .team-content .name a {
  color: #ffffff;
}

.single-team .team-content .name::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 20px;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
  z-index: -1;
  transition: all 0.3s linear;
}

.single-team .team-content .designation {
  font-size: 12px;
  line-height: 24px;
  position: relative;
  z-index: 1;
  color: #ffffff;
}

.single-team .team-content .team-social {
  opacity: 0;
  visibility: hidden;
  margin-bottom: -25px;
  position: relative;
  z-index: 1;
  transition: all 0.3s linear;
}

.single-team .team-content .team-social .social li {
  display: inline-block;
}

.single-team .team-content .team-social .social li + li {
  margin-left: 10px;
}

.single-team .team-content .team-social .social li a {
  font-size: 14px;
  transform: translate3d(0, 0, 0);
  color: #ffffff;
  transition: all 0.3s linear;
}

.single-team:hover .team-content {
  bottom: 90px;
}

.single-team:hover .team-content .name::before {
  height: 145px;
  width: 80px;
  transform: translateY(-35%);
}

.single-team:hover .team-content .team-social {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
}

.single-team:hover .team-content .team-social .social li a:hover {
  transform: translateY(-5px);
}

.team-active .swiper-pagination {
  position: relative;
  margin-top: 50px;
}

.team-active .swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px;
}

.techwix-team-section-02 {
  background: #010f31;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.techwix-team-section-03 .team-wrap {
  margin-top: -30px;
}

.techwix-team-section-03 .team-wrap .single-team {
  margin-top: 30px;
}

.techwix-team-section-03 .team-wrap .single-team .team-img a img {
  width: 100%;
}

/*--
/*  13 - Blog CSS
/*----------------------------------------*/
.techwix-blog-section {
  padding-bottom: 70px;
}

.techwix-blog-wrap {
  padding-top: 20px;
}

.single-blog {
  margin-top: 30px;
}

.single-blog .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.single-blog .blog-image a {
  display: block;
}

.single-blog .blog-image a img {
  transition: all 0.3s linear;
}

.single-blog .blog-image .top-meta {
  position: absolute;
  top: 35px;
  left: 30px;
}

.single-blog .blog-image .top-meta .date {
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  text-align: center;
  line-height: 12px;
  padding: 10px;
}

.single-blog .blog-image .top-meta .date span {
  font-size: 28px;
  line-height: 18px;
  font-weight: 500;
  line-height: 30px;
  display: block;
}

.single-blog .blog-content {
  padding: 25px 30px 40px;
  background: #ffffff;
  width: 90%;
  position: relative;
  left: 50%;
  bottom: 10px;
  transform: translateX(-48%);
  margin-top: -40px;
}

@media only screen and (max-width: 1199px) {
  .single-blog .blog-content {
    padding: 25px 20px 40px;
    width: 95%;
  }
}

.single-blog .blog-content .blog-meta span {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  color: #999999;
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-meta span {
    margin-right: 12px;
  }
}

.single-blog .blog-content .blog-meta span i {
  color: #086ad8;
  margin-right: 5px;
}

.single-blog .blog-content .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: #0e0e0e;
  padding-right: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: 1199px) {
  .single-blog .blog-content .title {
    padding-right: 0;
  }
}

.single-blog .blog-btn .blog-btn-link {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 15px;
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link i {
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link:hover i {
  margin-left: 5px;
}

.single-blog:hover .blog-image a img {
  transform: scale(1.1);
}

.techwix-blog-grid-wrap {
  margin-top: -30px;
}

/*--
/*  03 - Features CSS
/*----------------------------------------*/
.features-wrap {
  margin-top: -170px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .features-wrap {
    margin-top: -130px;
  }
}

.features-item {
  display: flex;
  align-items: center;
  background: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 37px 79px 0px rgba(0, 0, 0, 0.13);
  padding: 35px;
  padding-right: 50px;
  margin-top: 30px;
  transition: all 0.3s linear;
}

@media only screen and (max-width: 449px) {
  .features-item {
    padding: 30px;
    padding-right: 25px;
  }
}

.features-item .features-content {
  flex: 1;
  margin-left: 20px;
}

@media only screen and (max-width: 449px) {
  .features-item .features-content {
    margin-left: 15px;
  }
}

.features-item .features-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

@media only screen and (max-width: 449px) {
  .features-item .features-content .title {
    font-size: 20px;
  }
}

.techwix-features-section-02 .features-wrap-02 {
  margin-top: -140px;
  position: relative;
  z-index: 5;
}

.techwix-features-section-02 .features-item {
  background: linear-gradient(90deg, #693eaa 0%, #01ebf2 100%);
  padding: 40px 35px;
  padding-right: 90px;
}

.techwix-features-section-02 .features-item .features-content {
  margin-left: 40px;
}

.techwix-features-section-02 .features-item .features-content .title {
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: #ffffff;
}

.techwix-features-section-02 .features-item .features-content .title:hover a {
  color: #11ddf5;
}

.techwix-features-section-02 .features-item .features-content p {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 15px;
}

.techwix-features-section-02 .features-item .features-content .lern-more {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #11ddf5;
  margin-top: 5px;
}

.techwix-features-section-02 .features-item .features-content .lern-more i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.techwix-features-section-02 .features-item .features-content .lern-more:hover i {
  margin-left: 5px;
}

@media only screen and (max-width: 1399px) {
  .techwix-features-section-02 .features-item {
    padding-right: 38px;
  }
  .techwix-features-section-02 .features-item .features-content {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .techwix-features-section-02 .features-wrap-02 {
    margin-top: -90px;
  }
  .techwix-features-section-02 .features-item {
    padding: 40px 25px;
    padding-right: 25px;
  }
  .techwix-features-section-02 .features-item .features-content {
    margin-left: 25px;
  }
  .techwix-features-section-02 .features-item .features-content .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 449px) {
  .techwix-features-section-02 .features-item {
    align-items: self-start;
    flex-direction: column;
    gap: 25px;
  }
  .techwix-features-section-02 .features-item .features-content {
    margin-left: 0px;
  }
}

/*--
/*  03 - Video CSS
/*----------------------------------------*/
.techwix-video-section .video-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  padding-top: 150px;
  padding-bottom: 195px;
  margin-top: -250px;
  overflow: hidden;
  position: relative;
  z-index: 5;
}

.techwix-video-section .video-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(45, 45, 78, 0.5);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.techwix-video-section .video-wrap .shape-1 {
  position: absolute;
  left: 50px;
  top: 120px;
  z-index: -1;
}

.techwix-video-section .video-wrap .shape-1 svg {
  fill: #41b7fe;
}

.techwix-video-section .video-wrap .shape-2 {
  position: absolute;
  right: -14%;
  bottom: -40%;
  z-index: -1;
}

.techwix-video-section .video-wrap .shape-3 {
  position: absolute;
  right: 6%;
  bottom: 30%;
  z-index: -1;
}

.techwix-video-section .video-wrap .shape-3 svg {
  fill: #ffffff;
  opacity: 0.5;
}

.techwix-video-section .video-wrap .video-content {
  max-width: 385px;
  margin: 0 auto;
}

.techwix-video-section .video-wrap .video-content .play-btn-02 {
  position: inherit;
  margin-top: 60px;
}

.techwix-video-section .video-wrap .video-content .play-btn-02 a::before {
  border: 2px solid #086ad8;
}

.techwix-video-section .video-wrap .video-content .play-btn-02 a::after {
  border: 2px solid #086ad8;
}

@media only screen and (max-width: 1199px) {
  .techwix-video-section .video-wrap {
    padding-top: 110px;
    padding-bottom: 160px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-video-section .video-wrap {
    padding-top: 100px;
    padding-bottom: 130px;
  }
  .techwix-video-section .video-wrap .shape-2 {
    right: -32%;
  }
}

@media only screen and (max-width: 767px) {
  .techwix-video-section .video-wrap .shape-2 {
    right: -53%;
  }
}

@media only screen and (max-width: 575px) {
  .techwix-video-section .video-wrap .shape-1 {
    display: none;
  }
  .techwix-video-section .video-wrap .shape-2 {
    display: none;
  }
  .techwix-video-section .video-wrap .shape-3 {
    display: none;
  }
}

/*--
/*  16 - Pricing CSS
/*----------------------------------------*/
.techwix-pricing-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap {
  padding-top: 30px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 45px 55px 70px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing::before {
  content: "";
  position: absolute;
  right: -38%;
  top: -42%;
  border: 30px solid #ffffff;
  width: 445px;
  height: 445px;
  border-radius: 50%;
  z-index: -1;
}

@media only screen and (max-width: 1399px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
    padding: 45px 30px 50px;
  }
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-badge .title {
  font-size: 36px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 575px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-badge .title {
    font-size: 24px;
  }
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .currency {
  font-size: 30px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  display: inline-block;
  position: relative;
  bottom: -10px;
}

@media only screen and (max-width: 991px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .currency {
    font-size: 25px;
  }
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price {
  font-size: 90px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  display: inline-block;
  color: #086ad8;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price span {
  font-size: 18px;
  font-weight: 500;
  color: #959595;
}

@media only screen and (max-width: 991px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price {
    font-size: 60px;
  }
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content {
  padding-top: 55px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-list li {
  font-size: 18px;
  line-height: 30px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-list li + li {
  margin-top: 10px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn {
  padding-top: 40px;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
  height: 50px;
  line-height: 48px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  padding: 0 50px;
}

@media only screen and (max-width: 1399px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
    padding: 0 30px;
    font-size: 16px;
  }
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active {
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active::before {
  opacity: 0.2;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-badge .title {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .currency {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .price {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .price span {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-list li {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-list li i {
  color: #ffffff;
}

.techwix-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-btn .pricing-contact {
  color: #5149f3;
}

/*--
/*  03 - Solution CSS
/*----------------------------------------*/
.solution-content-wrap {
  padding-top: 40px;
}

.solution-item {
  position: relative;
  margin-top: 28px;
}

.solution-item .solution-img a {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: block;
}

.solution-item .solution-img a::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 62%);
  transition: all 0.3s linear;
  opacity: 0.6;
  z-index: 1;
}

.solution-item .solution-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 40px 110px 120px 85px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}

@media only screen and (max-width: 1199px) {
  .solution-item .solution-content {
    padding: 40px 60px 90px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .solution-item .solution-content {
    padding: 40px 35px 70px 40px;
  }
}

@media only screen and (max-width: 449px) {
  .solution-item .solution-content {
    padding: 40px 35px 45px 35px;
  }
}

.solution-item .solution-content .solution-title {
  flex: 1;
}

.solution-item .solution-content .solution-title .sub-title {
  font-size: 14px;
  line-height: 27px;
  font-weight: 500;
  color: #ffffff;
}

.solution-item .solution-content .solution-title .title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .solution-item .solution-content .solution-title .title {
    font-size: 20px;
  }
}

.solution-item .solution-content .play-btn {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #ffffff;
  color: #086ad8;
  border-radius: 50%;
}

.solution-item .solution-content .play-btn:hover {
  background: #086ad8;
  color: #ffffff;
}

.solution-item.solution-item-big .solution-content .solution-title {
  padding-right: 110px;
}

@media only screen and (max-width: 767px) {
  .solution-item.solution-item-big .solution-content .solution-title {
    padding-right: 0px;
  }
}

.solution-item.solution-item-sm .solution-content {
  padding: 40px 45px 45px 55px;
}

@media only screen and (max-width: 1199px) {
  .solution-item.solution-item-sm .solution-content {
    padding: 35px 25px 30px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .solution-item.solution-item-sm .solution-content {
    padding: 40px 45px 45px 55px;
  }
}

@media only screen and (max-width: 449px) {
  .solution-item.solution-item-sm .solution-content {
    padding: 5px 30px 25px 25px;
  }
}

.solution-item.solution-item-sm .solution-content .solution-title {
  padding-right: 50px;
}

@media only screen and (max-width: 449px) {
  .solution-item.solution-item-sm .solution-content .solution-title {
    padding-right: 0px;
  }
}

/*--
/*  20 - Page Banner CSS
/*----------------------------------------*/
.page-banner-section {
  padding-top: 85px;
  min-height: 670px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.page-banner-section .shape-1 {
  position: absolute;
  left: -32%;
  top: -35%;
  z-index: -1;
}

.page-banner-section .shape-2 {
  position: absolute;
  left: -11%;
  top: -30%;
  z-index: -1;
}

.page-banner-section .shape-3 {
  position: absolute;
  right: -2%;
  bottom: -27%;
  z-index: -1;
}

.page-banner-section .shape-4 {
  position: absolute;
  right: -26%;
  bottom: -81%;
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .page-banner-section {
    min-height: 520px;
  }
  .page-banner-section .shape-1 {
    left: -70%;
    top: -47%;
  }
  .page-banner-section .shape-2 {
    left: -30%;
    top: -55%;
  }
  .page-banner-section .shape-3 {
    right: -15%;
    bottom: -45%;
  }
  .page-banner-section .shape-4 {
    right: -50%;
    bottom: -130%;
  }
}

@media only screen and (max-width: 991px) {
  .page-banner-section {
    min-height: 450px;
  }
  .page-banner-section .shape-1 {
    left: -95%;
    top: -58%;
  }
  .page-banner-section .shape-2 {
    left: -41%;
    top: -79%;
  }
  .page-banner-section .shape-3 {
    right: -25%;
    bottom: -60%;
  }
  .page-banner-section .shape-4 {
    right: -90%;
    bottom: -125%;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-section {
    min-height: 350px;
  }
  .page-banner-section .shape-1 {
    display: none;
  }
  .page-banner-section .shape-2 {
    display: none;
  }
  .page-banner-section .shape-3 {
    display: none;
  }
  .page-banner-section .shape-4 {
    display: none;
  }
}

.page-banner .title {
  font-size: 60px;
  line-height: 1.25;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .page-banner .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .title {
    font-size: 30px;
  }
}

.page-banner .breadcrumb {
  margin-bottom: 0;
  margin-top: 10px;
}

.page-banner .breadcrumb .breadcrumb-item {
  color: #ffffff;
  font-size: 20px;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner .breadcrumb .breadcrumb-item {
    font-size: 16px;
  }
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 8px;
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  font-family: flaticon !important;
  font-size: 20px;
  padding-right: 5px;
  color: #ffffff;
}

/*--
/*  17 - Blog Standard CSS
/*----------------------------------------*/
.blog-standard-wrap {
  margin-top: -40px;
}

.blog-standard-wrap .blog-post-wrap {
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .blog-standard-wrap .blog-post-wrap {
    padding-right: 0;
  }
}

.blog-standard-wrap .blog-post-wrap .pagination {
  margin-top: 55px;
}

.single-blog-post {
  margin-top: 40px;
}

.single-blog-post.single-blog .blog-content {
  padding: 40px 35px 0px;
  width: 94%;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  margin-top: -60px;
}

@media only screen and (max-width: 767px) {
  .single-blog-post.single-blog .blog-content {
    padding: 40px 30px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog-post.single-blog .blog-content {
    padding: 25px 0px 0px;
    width: 100%;
    margin-top: 0;
  }
}

.single-blog-post.single-blog .blog-content .blog-meta .tag {
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  color: #086ad8;
}

.single-blog-post.single-blog .blog-content .title {
  font-size: 36px;
  line-height: 1.1;
}

@media only screen and (max-width: 767px) {
  .single-blog-post.single-blog .blog-content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog-post.single-blog .blog-content .title {
    font-size: 26px;
  }
}

.single-blog-post.single-blog .blog-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 15px;
  transition: all 0.3s linear;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link i {
  transition: all 0.3s linear;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link:hover i {
  margin-left: 5px;
}

.blog-sidebar {
  margin-top: 40px;
  margin-left: 30px;
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 1199px) {
  .blog-sidebar {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .blog-sidebar {
    margin-left: 0px;
  }
}

.blog-sidebar .sidebar-widget-1 {
  margin-top: 0;
}

.sidebar-widget {
  margin-top: 45px;
}

.sidebar-widget .search-form {
  position: relative;
}

.sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  border: 0;
  padding-right: 35px;
  background: #f2f4f7;
  color: #415674;
  font-weight: 700;
  outline: none;
  overflow: hidden;
}

.sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #898c94;
  font-weight: 400;
}

.sidebar-widget .search-form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  color: #111111;
  transition: all 0.3s linear;
}

.sidebar-widget .search-form button:hover {
  background: #086ad8;
  color: #ffffff;
}

.sidebar-widget .widget-title {
  margin-bottom: 25px;
}

.sidebar-widget .widget-title .title {
  font-size: 20px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #0e0e0e;
  display: inline-block;
}

.sidebar-widget .recent-posts ul li {
  border-top: 1px solid #e5e5e5;
  padding-top: 30px;
  margin-top: 30px;
}

.sidebar-widget .recent-posts ul li:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.sidebar-widget .recent-posts ul li .post-link {
  display: flex;
  align-items: center;
}

.sidebar-widget .recent-posts ul li .post-link .post-thumb {
  margin-right: 20px;
}

.sidebar-widget .recent-posts ul li .post-link .post-thumb img {
  border-radius: 50%;
  image-rendering: crisp-edges;
}

.sidebar-widget .recent-posts ul li .post-link .post-text {
  flex: 1;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .title {
  font-size: 16px;
  line-height: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  transition: all 0.3s linear;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .post-meta {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: #838383;
  display: inline-block;
  margin-top: 5px;
}

.sidebar-widget .recent-posts ul li .post-link .post-text .post-meta i {
  color: #086ad8;
  margin-right: 5px;
}

.sidebar-widget .recent-posts ul li .post-link:hover .post-text .title {
  color: #086ad8;
}

.sidebar-widget .widget-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  padding: 60px 40px 90px;
}

.sidebar-widget .widget-banner .banner-content {
  max-width: 160px;
}

.sidebar-widget .widget-banner .banner-content .title {
  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  color: #ffffff;
}

.sidebar-widget .widget-banner .banner-content .btn {
  height: 40px;
  line-height: 38px;
  font-size: 14px;
  padding: 0 40px;
  margin-top: 30px;
}

.sidebar-widget .category .cate-item {
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  margin-top: 15px;
}

.sidebar-widget .category .cate-item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.sidebar-widget .category .cate-item a {
  display: flex;
  font-size: 15px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #29303c;
  transition: all 0.3s linear;
}

.sidebar-widget .category .cate-item a i {
  font-size: 10px;
  line-height: 24px;
  color: #086ad8;
  margin-right: 5px;
}

.sidebar-widget .category .cate-item a .post-count {
  margin-left: auto;
  align-items: flex-start;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background: #e4f2f8;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  color: #086ad8;
  transition: all 0.3s linear;
}

.sidebar-widget .category .cate-item a:hover {
  color: #086ad8;
}

.sidebar-widget .category .cate-item a:hover .post-count {
  background: #086ad8;
  color: #ffffff;
}

.sidebar-widget .sidebar-tag li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 4px;
}

.sidebar-widget .sidebar-tag li a {
  display: inline-block;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  background: #f9f9f9;
  transition: all 0.3s linear;
}

.sidebar-widget .sidebar-tag li a:hover {
  background-color: #086ad8;
  color: #ffffff;
}

/*--Page Pagination--*/
.techwix-pagination {
  margin-top: 60px;
}

.techwix-pagination .pagination li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  transition: all 0.3s linear;
}

.techwix-pagination .pagination li a.active {
  background-color: #086ad8;
  color: #ffffff;
}

/*--
/*  18 - Blog Details CSS
/*----------------------------------------*/
.blog-details-section .blog-details-wrap {
  margin-top: -40px;
}

.blog-details-post {
  padding-right: 30px;
}

.blog-details-post .single-blog-post {
  margin-top: 40px;
}

.blog-details-post .single-blog-post.single-blog .blog-content {
  padding: 30px 0px 0px;
  width: 100%;
  left: 0;
  bottom: 0px;
  transform: translateX(0);
  margin-top: 0px;
}

.blog-details-post .single-blog-post.single-blog .blog-content .blog-meta .tag {
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  color: #086ad8;
}

.blog-details-post .single-blog-post.single-blog .blog-content .title {
  font-size: 36px;
  line-height: 43px;
}

.blog-details-post .single-blog-post.single-blog .blog-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.blog-details-post .blog-details-content .blog-quote {
  background-color: none;
  position: relative;
  margin-left: 50px;
  margin-top: 40px;
}

.blog-details-post .blog-details-content .blog-quote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #086ad8;
  width: 6px;
  height: 105px;
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote::after {
    display: none;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote {
  padding-right: 125px;
  padding-left: 25px;
  margin-bottom: 0;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .blog-quote .blockquote {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote .blockquote {
    margin-left: 0;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote:not(:first-child) {
  margin-top: 25px;
}

.blog-details-post .blog-details-content .blog-quote .blockquote:not(:last-child) {
  margin-bottom: 25px;
}

.blog-details-post .blog-details-content .blog-quote .blockquote p {
  display: inline;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  color: #333333;
  line-height: 32px;
  font-weight: 600;
  margin-top: 0;
}

.blog-details-post .blog-details-content .blog-details-text p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 50px;
}

.blog-details-post .blog-details-content .blog-details-tag-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e5;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag {
  padding-top: 20px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget .label {
  font-size: 14px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #333333;
  margin-right: 10px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget .sidebar-tag li {
  margin-bottom: 0px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li {
  display: inline-block;
  margin-right: 10px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li:last-child {
  margin-right: 0;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li a {
  display: inline-block;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ededed;
  text-align: center;
  color: #086ad8;
  border-radius: 50%;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li a:hover {
  opacity: 0.9;
}

.blog-details-post .blog-details-content .techwix-post-pagination {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.16);
  padding: 10px 30px;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .techwix-post-pagination {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .techwix-post-pagination {
    padding: 0 10px;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination::before {
  position: absolute;
  content: '';
  width: 1px;
  background-color: #e1e1e1;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-post .blog-details-content .techwix-post-pagination::before {
    width: auto;
    height: 1px;
    left: 30px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination .previous-post,
.blog-details-post .blog-details-content .techwix-post-pagination .next-post {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .blog-details-post .blog-details-content .techwix-post-pagination .previous-post,
  .blog-details-post .blog-details-content .techwix-post-pagination .next-post {
    width: 100%;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post {
    padding: 20px 0;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-thumb {
  flex-shrink: 0;
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-thumb a img {
  border-radius: 10px;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-thumb a i {
  font-size: 14px;
  color: #086ad8;
  padding: 0 10px;
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content {
  flex-grow: 1;
  padding: 0 20px;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content {
    padding: 0 13px;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content .title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content .title {
    font-size: 15px;
  }
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content .date {
  font-size: 12px;
  line-height: 22px;
  color: #838383;
}

.blog-details-post .blog-details-content .techwix-post-pagination .blog-pagination-post .post-content .date i {
  color: #086ad8;
  margin-right: 6px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form {
  margin-top: 50px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-title {
  font-size: 22px;
  line-height: 30px;
  color: #0e0e0e;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form p {
  font-size: 14px;
  line-height: 30px;
  color: #686f7a;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap {
  padding-top: 10px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form {
  margin-top: 20px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Barlow", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Barlow", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Barlow", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  font-family: "Barlow", sans-serif;
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:focus {
  outline: none;
  border-color: #086ad8;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form textarea.form-control {
  height: 135px;
  padding-top: 15px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  resize: none;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .form-btn .btn {
  height: 40px;
  line-height: 38px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  margin-top: 25px;
  font-size: 16px;
  padding: 0 40px;
}

/*--
/*  24 - Login Register CSS
/*----------------------------------------*/
.login-register-section .login-register-wrap {
  margin-top: -50px;
}

.login-register-section .login-register-wrap .section-title .title {
  font-size: 36px;
  font-weight: 700;
  color: #333333;
  line-height: 1.2;
  margin-top: -8px;
}

.login-register-section .login-register-wrap .login-register-box {
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  margin-top: 50px;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form {
  margin-top: 20px;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  color: #787884;
  font-weight: 400;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-control:focus {
  outline: none;
  border-color: #086ad8;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form textarea.form-control {
  height: 135px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  padding-top: 15px;
  resize: none;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-check-input {
  margin-right: 8px;
  margin-top: 5px;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-check-input:focus {
  box-shadow: none;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .single-form .form-check-label {
  display: inline;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0;
  text-transform: none;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .form-btn {
  margin-top: 30px;
}

.login-register-section .login-register-wrap .login-register-box .login-register-form .form-btn .btn {
  height: 50px;
  line-height: 45px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  width: 100%;
}

/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/
/*--
/*  5.1 - Sidebar Widget CSS
/*----------------------------------------*/
.sidebar-wrap {
  border-left: 1px solid #eeeeee;
  height: 100%;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrap {
    border-left: none;
    height: auto;
    padding-top: 0;
  }
}

.widget {
  margin-bottom: 40px;
  color: #4c4d56;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-left: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .widget .widget-title {
    padding-left: 0;
  }
}

/*--
/*  25.1 - Footer Widget CSS
/*----------------------------------------*/
.footer-widget-wrap {
  padding-top: 70px;
  padding-bottom: 75px;
}

.footer-widget {
  margin-top: 30px;
}

.widget-info {
  padding-top: 20px;
}

.widget-info ul li {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.widget-info ul li .info-icon i {
  display: inline-block;
  color: #086ad8;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}

.widget-info ul li .info-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #c4c5c7;
}

.footer-widget-about {
  margin-top: 15px;
}

.footer-widget-about p {
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 110px;
  margin-top: 20px;
}

@media only screen and (max-width: 1399px) {
  .footer-widget-about p {
    padding-right: 0;
  }
}

.footer-social .social {
  margin-top: 25px;
}

.footer-social .social li {
  display: inline-block;
}

.footer-social .social li + li {
  margin-left: 5px;
}

.footer-social .social li a {
  width: 35px;
  height: 35px;
  line-height: 37px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  background: #ffffff;
  color: #086ad8;
}

.footer-social .social li a:hover {
  color: #086ad8;
}

.footer-widget .footer-widget-title {
  font-size: 20px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #ffffff;
}

.widget-link {
  padding-top: 22px;
}

.widget-link .link li {
  margin-top: 7px;
}

.widget-link .link li a {
  font-size: 14px;
  color: #c4c5c7;
  transition: all 0.3s linear;
}

.widget-link .link li a:hover {
  color: #086ad8;
  padding-left: 10px;
}

.footer-section-02 .footer-social .social li a {
  color: #ff5538;
}

.footer-section-02 .footer-social .social li a:hover {
  color: #ff5538;
}

.footer-section-02 .widget-link {
  padding-top: 22px;
}

.footer-section-02 .widget-link .link li a:hover {
  color: #ff5538;
}

.footer-section-02 .widget-info ul li .info-icon i {
  color: #ff5538;
}

.footer-section-02 .widget-info ul li .info-text a:hover {
  color: #ff5538;
}

.footer-section-04 .footer-widget-about p {
  color: #0e0e0e;
}

.footer-section-04 .footer-widget .footer-widget-title {
  color: #0e0e0e;
}

.footer-section-04 .widget-link {
  padding-top: 22px;
}

.footer-section-04 .widget-link .link li a {
  color: #0e0e0e;
}

.footer-section-04 .widget-link .link li a:hover {
  color: #086ad8;
}

.footer-section-04 .widget-info ul li .info-text {
  color: #0e0e0e;
}

.footer-section-05 .footer-social .social li a {
  color: #11ddf5;
}

.footer-section-05 .footer-social .social li a:hover {
  color: #11ddf5;
}

.footer-section-05 .widget-link {
  padding-top: 22px;
}

.footer-section-05 .widget-link .link li a:hover {
  color: #11ddf5;
}

.footer-section-05 .widget-info ul li .info-icon i {
  color: #11ddf5;
}

.footer-section-05 .widget-info ul li .info-text a:hover {
  color: #11ddf5;
}

/*--
/*  25. Footer CSS
/*----------------------------------------*/
.footer-section {
  background: #010717;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.footer-logo {
  max-width: 170px;
}

.footer-copyright-area {
  border-top: 1px solid #1b202f;
  padding-top: 15px;
  padding-bottom: 30px;
}

.copyright-text {
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    text-align: center;
  }
}

.copyright-text p {
  font-size: 14px;
  color: #c4c5c7;
}

.footer-section-02 {
  background: #000000;
}

.footer-section-02 .footer-widget-wrap {
  padding-top: 130px;
}

.footer-section-03 .footer-widget-wrap {
  padding-top: 140px;
}

.footer-section-04 .footer-copyright-area {
  border-top: 1px solid #e0e0e0;
}

.footer-section-04 .copyright-text p {
  font-size: 14px;
  color: #0e0e0e;
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 93, 224, 0.2);
  opacity: 0;
  visibility: hidden;
  transform: translateX(130%);
  z-index: 99;
  transition: all 200ms linear;
}

.progress-wrap::after {
  position: absolute;
  content: "\f108";
  font-family: flaticon !important;
  text-align: center;
  line-height: 45px;
  font-size: 18px;
  color: #086ad8;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  transform: rotate(90deg);
  cursor: pointer;
  display: block;
  z-index: 1;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #086ad8;
  strokeWidth: 4;
  box-sizing: border-box;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.progress-wrap-3 {
  box-shadow: inset 0 0 0 2px rgba(255, 85, 56, 0.2);
}

.progress-wrap-3::after {
  color: #ff5538;
}

.progress-wrap-3 svg.progress-circle path {
  stroke: #ff5538;
}
