.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 425px) {
  [data-aos] {
    opacity: 1; /* Disabling animation effects */
    transition: none;
  }
}
.web-des {
  background-color: #40E0D0;
}
.digital-mark {
  background-color: #FBE7A1;
}
.grapic-design {
  background-color: #46C7C7;
}
.bpo-kpo {
  background-color: #A0D6B4;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
